body {
  line-height: 0;
  width: 100%;
  height: 100vh;
  font-size: 14px;
  margin: 0;
  padding: 0;
}
#root {
  width: 100%;
  height: 100%;
  color: #000;
}
/* 子页面通用样式 header */
.subpage {
  width: 100%;
  height: 100%;
  background: #fff;
  color: #000;
  padding: 0;
  margin: 0;
}
.subpage .section,
.subpage .interlace-section {
  width: 100%;
  margin: 0 auto;
  display: block;
}
.subpage .interlace-section {
  background: #e3ecf5;
}
.header {
  width: 100%;
  display: block;
  position: absolute;
  top: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
@media screen and (max-width: 640px) {
  .header {
    height: 1.4rem;
  }
  .subpage header {
    color: #fff;
    padding-bottom: 0.2rem;
  }
  .subpage header h5 {
    line-height: 1rem;
    font-size: 0.4rem;
    font-weight: bold;
    padding-top: 1.4rem;
  }
  .subpage header h5,
  .subpage header p {
    width: 80%;
    display: block;
    margin-left: 10%;
    color: #fff;
  }
  .subpage header p {
    line-height: 0.5rem;
    font-size: 0.28rem;
  }
}
@media screen and (min-width: 640px) and (max-width: 1280px) {
  .subpage .section,
  .subpage .interlace-section {
    padding: 0.6rem 0;
  }
  .subpage header {
    min-height: 6.6rem;
    color: #fff;
  }
  .subpage header h5 {
    line-height: 1rem;
    font-size: 0.4rem;
    font-weight: bold;
    padding-top: 2.6rem;
  }
  .subpage header h5,
  .subpage header p {
    width: 80%;
    display: block;
    margin-left: 10%;
    color: #fff;
  }
  .subpage header p {
    line-height: 0.7rem;
    font-size: 0.4rem;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1600px) {
  .subpage .interlace-section {
    padding: 1% 0;
  }
  .subpage header {
    min-height: 9.6rem;
    color: #fff;
  }
  .subpage header h5,
  .subpage header p {
    width: 34%;
    display: block;
    margin-left: 10%;
    color: #fff;
  }
  .subpage header h5 {
    line-height: 1rem;
    font-size: 1rem;
    font-weight: bold;
    padding-top: 11%;
  }
  .subpage header p {
    line-height: 0.7rem;
    font-size: 0.4rem;
  }
}
@media screen and (min-width: 1600px) {
  .subpage .section,
  .subpage .interlace-section {
    padding: 2% 0;
  }
  .subpage header {
    min-height: 9.6rem;
    color: #fff;
  }
  .subpage header h5,
  .subpage header p {
    width: 34%;
    display: block;
    margin-left: 20%;
    color: #fff;
  }
  .subpage header h5 {
    line-height: 1rem;
    font-size: 1rem;
    font-weight: bold;
    padding-top: 7%;
  }
  .subpage header p {
    line-height: 0.9rem;
    font-size: 0.4rem;
  }
}

.subpage .one-section {
  position: relative;
  background: url(../../img/yj_bj.jpg) no-repeat center bottom;
  background-size: contain;
}
.subpage .software-customer-img {
  margin: 0 auto;
}
.subpage .software-customer-img img {
  border: 1px solid #dcdcdc;
  display: inline-block;
}
.subpage .one-section .software-section {
  margin: 0 auto;
  text-align: center;
}
.subpage .one-section .software-section .top li {
  display: inline-block;
  vertical-align: top;
}
.subpage .one-section .software-section .top .eam {
  text-align: center;
  background: url(../../img/ruanj1-3.jpg) no-repeat center center;
  background-size: contain;
}
.subpage .one-section .software-section .top .stock {
  text-align: center;
  background: url(../../img/ruanj1-3.jpg) no-repeat center center;
  background-size: contain;
}
.subpage .one-section .software-section .top .cloud {
  text-align: center;
  background: url(../../img/ruanj1-2.jpg) no-repeat center center;
  background-size: contain;
}
.subpage .one-section .software-section .top .edge {
  text-align: center;
  background: url(../../img/ruanj1.jpg) no-repeat center center;
  background-size: contain;
}
.subpage .one-section .software-section .top li:last-child {
  text-align: left;
  color: #000;
}
.subpage .one-section .software-section .top li:last-child h1 {
  font-weight: bold;
}
.subpage .one-section .software-section .top li:last-child p {
  text-indent: 2em;
}
.subpage .one-section .software-section ul,
.subpage .one-section .software-section li {
  list-style: none;
}
.subpage .one-section .software-section .bottom li {
  display: inline-block;
  vertical-align: top;
  word-break: break-all;
  background: white;
  box-shadow: 0px 0px 5px #e2e2e2;
}
@media screen and (max-width: 640px) {
  .subpage .one-section {
    width: 100%;
    padding-bottom: 0.2rem;
  }
  .subpage .software-customer-img {
    width: 96%;
    padding: 0.2rem 0 0.4rem 0;
  }
  .subpage .software-customer-img img {
    width: 31%;
    margin: 1%;
  }
  .subpage .one-section .software-section {
    width: 96%;
  }
  .subpage .one-section .software-section ul {
    padding: 0;
  }
  .subpage .one-section .software-section .top {
    margin-top: 0.2rem;
    margin-bottom: 0;
  }
  .subpage .one-section .software-section .top li {
    display: inline-block;
    vertical-align: middle;
  }
  .subpage .one-section .software-section .top li:first-child {
    display: none;
  }
  .subpage .one-section .software-section .top li:last-child {
    width: 100%;
  }
  .subpage .one-section .software-section .top li:last-child h1 {
    line-height: 0.5rem;
    font-size: 0.35rem;
    text-align: center;
  }
  .subpage .one-section .software-section .top li:last-child p {
    line-height: 0.5rem;
    font-size: 0.26rem;
  }
  .subpage .one-section .software-section .bottom li {
    width: 48%;
    height: 2.5rem;
    margin: 1%;
    padding: 0.3rem 1%;
    font-size: 0.25rem;
  }
  .subpage .one-section .software-section .bottom li p {
    margin-top: 0.1rem;
    line-height: 0.4rem;
  }
}
@media screen and (min-width: 640px) and (max-width: 1280px) {
  .subpage .one-section {
    width: 100%;
    padding-bottom: 1rem;
  }
  .subpage .software-customer-img {
    width: 96%;
    padding: 1rem 0;
  }
  .subpage .software-customer-img img {
    width: 19%;
    margin-right: 1%;
  }
  .subpage .one-section .software-section {
    width: 96%;
  }
  .subpage .one-section .software-section ul {
    padding: 0;
  }
  .subpage .one-section .software-section .top {
    margin-top: 1rem;
    margin-bottom: 0.6rem;
  }
  .subpage .one-section .software-section .top li {
    height: 5.4rem;
  }
  .subpage .one-section .software-section .top li:first-child {
    width: 37%;
  }
  .subpage .one-section .software-section .top li:last-child {
    width: 63%;
    padding: 0 3%;
    margin-top: 0rem;
  }
  .subpage .one-section .software-section .top li:last-child h1 {
    font-size: 0.6rem;
    text-align: center;
  }
  .subpage .one-section .software-section .top li:last-child p {
    line-height: 0.8rem;
  }
  .subpage .one-section .software-section .bottom li {
    width: 23%;
    height: 4.6rem;
    margin-right: 2%;
    padding: 2% 1%;
    font-size: 0.4rem;
  }
  .subpage .one-section .software-section .bottom li p {
    margin-top: 0.4rem;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1600px) {
  .subpage .one-section {
    width: 100%;
    padding-bottom: 1.4rem;
  }
  .subpage .software-customer-img {
    width: 68%;
    padding: 1rem 0;
  }
  .subpage .software-customer-img img {
    width: 19%;
    margin-right: 1%;
  }
  .subpage .one-section .software-section {
    width: 70%;
  }
  .subpage .one-section .software-section .top {
    margin-top: 1rem;
    margin-bottom: 0.6rem;
  }
  .subpage .one-section .software-section .top li {
    height: 10rem;
  }
  .subpage .one-section .software-section .top li:first-child {
    width: 37%;
  }
  .subpage .one-section .software-section .top li:last-child {
    width: 63%;
    padding-top: 2rem;
    padding-left: 2rem;
  }
  .subpage .one-section .software-section .top li:last-child h1 {
    font-size: 1rem;
  }
  .subpage .one-section .software-section .top li:last-child p {
    line-height: 0.8rem;
  }
  .subpage .one-section .software-section .bottom li {
    width: 23%;
    height: 4.2rem;
    margin-right: 2%;
    padding: 2% 1%;
    font-size: 0.45rem;
  }
  .subpage .one-section .software-section .bottom li p {
    margin-top: 0.4rem;
  }
}
@media screen and (min-width: 1600px) {
  .subpage .one-section {
    width: 100%;
    padding-bottom: 1.4rem;
  }
  .subpage .software-customer-img {
    width: 68%;
    padding: 1rem 0;
  }
  .subpage .software-customer-img img {
    width: 19%;
    margin-right: 1%;
  }
  .subpage .one-section .software-section {
    width: 70%;
  }
  .subpage .one-section .software-section .top {
    margin-top: 1rem;
    margin-bottom: 0.6rem;
  }
  .subpage .one-section .software-section .top li {
    min-height: 8rem;
  }
  .subpage .one-section .software-section .top li:first-child {
    width: 37%;
  }
  .subpage .one-section .software-section .top li:last-child {
    width: 63%;
    padding-top: 1.4rem;
    padding-left: 1rem;
  }
  .subpage .one-section .software-section .top li:last-child h1 {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1rem;
  }
  .subpage .one-section .software-section .top li:last-child p {
    line-height: 0.8rem;
    font-size: 0.4rem;
  }
  .subpage .one-section .software-section .bottom li {
    width: 23%;
    height: 4.2rem;
    margin-right: 2%;
    padding: 2% 1%;
    font-size: 0.45rem;
  }
  .subpage .one-section .software-section .bottom li p {
    margin-top: 0.4rem;
    line-height: 0.6rem;
  }
}

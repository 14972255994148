.subpage .news-header{
	background-image: url(../../img/banner5.jpg);
	background-size: 100% 100%;
}
.subpage .section .news{
	margin: 0 auto;
	text-align: center;
}
.subpage .section .news .ant-tabs-tab{
	font-size: 0.45rem !important;
}
.subpage .section .news .news-container{
	margin: 0;
	text-align: left;
	border-bottom: 1px dashed #c1c1c1;
}
.subpage .section .news .news-container:last-child{
	border-bottom: none;
}
.subpage .section .news .news-container div{
	display: inline-block;
	vertical-align: middle;
	color: #666666;
}
.subpage .section .news .news-container div h5{
	color: #666666;
	font-size: 0.45rem;
	font-weight: bold;
}
.subpage .software-header{
	background-image: url(../../img/banner3.jpg);
	background-size: 100% 100%;
}
.subpage .one-section{
	position: relative;
	background: url(../../img/yj_bj.jpg) no-repeat center bottom;
	background-size: contain;
}
.subpage .software-customer-img{
	margin:0 auto;
}
.subpage .software-customer-img img{
	border:1px solid #dcdcdc;
	display: inline-block;
}
.subpage .one-section .software-section{
	margin: 0 auto;
	text-align: center;
}
.subpage .one-section .software-section .top li{
	display: inline-block;
	vertical-align: middle;
}
.subpage .one-section .software-section ul,
.subpage .one-section .software-section li{
	list-style: none;
}
.subpage .one-section .software-section .bottom li{
	display: inline-block;
	vertical-align: top;
	word-break:break-all;
	background: white;
	box-shadow: 0px 0px 5px #e2e2e2;
}
@media screen  and (max-width: 640px){
	.subpage .software-header{
		background-image: url(../../img/banner3.jpg);
		background-size: cover;
	}
	.subpage .section .news{
		width: 90%;
		margin: 0 auto;
	}
	.subpage .section .news .ant-tabs-tab{
		font-size: 0.3rem !important;
		margin: 0 0.4rem 0 0;
	}
	.subpage .section .news .news-container{
		padding: 0.1rem;
	}
	.subpage .section .news .news-container div:first-child{
		width: 100%;
		text-align: left;
	}
	.subpage .section .news .news-container div:first-child img{
		width: 50%;
	}
	.subpage .section .news .news-container div:last-child{
		width: 100%;
		text-align: left;
	}
	.subpage .section .news .news-container div h5{
		font-size: 0.35rem;
		line-height: 0.8rem;
	}	
	/* 新闻详情 */
	.news-item{
		width: 90%;
		margin:0 auto;
		text-align: center;
	}
	.news-item-nav{
		width: 100%;
		padding-left: 5%;
		display: block;
		height: 1rem;
		line-height: 1rem;
		text-align: left;
		font-size: 0.3rem;
		background: #f5f5f5;
	}
	.news-item-nav p{
		width: 60%;
		display: inline-block;
		text-align: left;
		height: 100%;
	}
	.news-item-nav p a{
		margin-right: 0.3rem;
		color:#888888 ;
	}
	.news-item-nav p span{
		color:#888888 ;
	}
	.news-item-nav div a:hover{
		color:#1890ff ;
	}
	.news-item-nav div a .anticon{
		margin-left: 0.1rem;
		color:#666666 ;
		font-size: 0.3rem;
		line-height: 1.4rem;
	}
	.news-item .article{
		padding: 0.2rem 0;
	}
	.news-item .article h1{
		font-size:0.3rem; 
		line-height:0.8rem;
		color:#666666;
		font-weight: 500;
	}
	.news-item .article h5{
		font-size:0.24rem; 
		line-height:0.6rem;
		color:#666666;
		font-weight: 100;
		border-bottom:solid 1px #d0d0d0
	}
	.news-item .article h6{
		font-size:0.28rem; 
		line-height:0.6rem;
		color:#666666;
		font-weight: normal;
		text-align: left;
		text-indent: 2em;
	}
}
@media screen  and (min-width: 640px) and (max-width:1280px) {
	.subpage .section .news{
		width: 92%;
	}
	.subpage .section .news .ant-tabs-tab{
		font-size: 0.45rem !important;
		margin: 0 1rem 0 0;
	}
	.subpage .section .news .news-container{
		padding: 0.8rem 0 0.8rem 0;
	}
	.subpage .section .news .news-container div:first-child{
		width: 20%;
	}
	.subpage .section .news .news-container div:first-child img{
		width: 90%;
	}
	.subpage .section .news .news-container div:last-child{
		width: 75%;
	}
	.subpage .section .news .news-container div h5{
		font-size: 0.45rem;
		line-height: 0.8rem;
	}	
	/* 新闻详情 */
	.news-item{
		width: 68%;
		margin:0 auto;
		text-align: center;
	}
	.news-item-nav{
		width: 100%;
		display: block;
		height: 1.4rem;
		line-height: 1.4rem;
		text-align: center;
		font-size: 0.45rem;
		background: #f5f5f5;
	}
	.news-item-nav p{
		width: 60%;
		display: inline-block;
		text-align: left;
		height: 100%;
	}
	.news-item-nav p a{
		margin-right: 0.3rem;
		color:#888888 ;
	}
	.news-item-nav p span{
		color:#888888 ;
	}
	.news-item-nav div a:hover{
		color:#1890ff ;
	}
	.news-item-nav div a .anticon{
		margin-left: 0.1rem;
		color:#666666 ;
		font-size: 0.3rem;
		line-height: 1.4rem;
	}
	.news-item .article{
		padding: 0.4rem 0;
	}
	.news-item .article h1{
		font-size:0.7rem; 
		line-height:1rem;
		color:#666666;
		font-weight: 500;
	}
	.news-item .article h5{
		font-size:0.4rem; 
		line-height:1rem;
		color:#666666;
		font-weight: 100;
		border-bottom:solid 1px #d0d0d0
	}
	.news-item .article h6{
		font-size:0.4rem; 
		line-height:0.8rem;
		color:#666666;
		font-weight: normal;
		text-align: left;
		text-indent: 2em;
	}
}
@media screen  and (min-width: 1280px){
	.subpage .section .news{
		width: 62%;
	}
	.subpage .section .news .ant-tabs-tab{
		font-size: 0.45rem !important;
		margin: 0 1rem 0 0;
	}
	.subpage .section .news .news-container{
		padding: 0.8rem 0 0.8rem 0;
	}
	.subpage .section .news .news-container div:first-child{
		width: 20%;
	}
	.subpage .section .news .news-container div:first-child img{
		width: 90%;
	}
	.subpage .section .news .news-container div:last-child{
		width: 75%;
	}
	.subpage .section .news .news-container div h5{
		font-size: 0.45rem;
		line-height: 0.8rem;
	}	
	/* 新闻详情 */
	.news-item{
		width: 68%;
		margin:0 auto;
		text-align: center;
	}
	.news-item-nav{
		width: 100%;
		display: block;
		height: 1.4rem;
		line-height: 1.4rem;
		text-align: center;
		font-size: 0.45rem;
		background: #f5f5f5;
	}
	.news-item-nav p{
		width: 60%;
		display: inline-block;
		text-align: left;
		height: 100%;
	}
	.news-item-nav p a{
		margin-right: 0.3rem;
		color:#888888 ;
	}
	.news-item-nav p span{
		color:#888888 ;
	}
	.news-item-nav div a:hover{
		color:#1890ff ;
	}
	.news-item-nav div a .anticon{
		margin-left: 0.1rem;
		color:#666666 ;
		font-size: 0.3rem;
		line-height: 1.4rem;
	}
	.news-item .article{
		padding: 0.4rem 0;
	}
	.news-item .article h1{
		font-size:0.7rem; 
		line-height:1rem;
		color:#666666;
		font-weight: 500;
	}
	.news-item .article h5{
		font-size:0.4rem; 
		line-height:1rem;
		color:#666666;
		font-weight: 100;
		border-bottom:solid 1px #d0d0d0
	}
	.news-item .article h6{
		font-size:0.4rem; 
		line-height:0.8rem;
		color:#666666;
		font-weight: normal;
		text-align: left;
		text-indent: 2em;
	}
}
.newsContiner .news .choose{
	position: relative;
}
.newsContiner .news .choose .choose-left,
.newsContiner .news .choose .choose-right{
	position: absolute;
	top: 50%;
	cursor: pointer;
	font-size: 20px;
	color: rgba(0, 0, 0, 0.1);
	border-radius: 50%;
	background-size: contain;	
	width: 30px;
	height: 30px;
	line-height: 30px;
	text-align: center;
}
.newsContiner .news .choose .choose-left:hover,
.newsContiner .news .choose .choose-right:hover{
	position: absolute;
	top: 50%;
	cursor: pointer;
	font-size: 20px;
	color: #fff;
	background: #0079e8;
	border-radius: 50%;
	background-size: contain;	
	width: 30px;
	height: 30px;
	line-height: 30px;
	text-align: center;
}
.home-left-footer{
	line-height:0.8rem;
	left: 0rem;
	color: #fff;
	display:block;
	position:absolute;
	border-top: 1px solid rgba(255, 255, 255, 0.2);
}
.home-left-footer .active{
	color: #000;
	background: rgba(255,255,255,0.8);
}
.home-footer{
	width: 100%;
	color: #fff;
	position:absolute;
	text-align:center;
	display: block;
	border-top: 1px solid rgba(255, 255, 255, 0.2);
}
/* 首页右侧 */
.home-change{
	display: block;
	position: absolute;
}
.home-change span{
	display: block;
	border-radius: 50%;
	background: #fff;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	cursor: pointer;
}
.home-change .active{
	display: block;
	border-radius: 50%;
	background: #000;
	border:2px solid #fff;
	-moz-border-radius: 50%;      
	-webkit-border-radius: 50%;    
	cursor: pointer;
}
.home-main,
.home-one,
.solution,
.productContiner,
.newsContiner,
.contactContiner{
	width: 100%;
	text-align:center;
}
.solution{
	background-image: url(../../img/Solution_bj.jpg);
	background-size: 100% 100%;
}
.home-main{
	background-image: url(../../img/three.gif);
	background-size: 100% 100%;
}
.home-one{
	background-image: url(../../img/about.jpg);
	background-size: 100% 100%;
}
.productContiner{
	background-image: url(../../img/product_bj.jpg);
	background-size: 100% 100%;
}
.newsContiner{
	background-image: url(../../img/news_bj.jpg);
	background-size: 100% 100%;
}
.contactContiner{
	background-image: url(../../img/contact_bj.jpg);
	background-size: 100% 100%;
}
.home-one .about,
.solution .center,
.productContiner .center,
.newsContiner .center,
.contactContiner .center{
	margin: 0 auto;
	text-align: left;
	color: #fff;
	line-height:0.6rem;
}
.home-one .about p,
.solution .center p{
	text-align: left;
	color: #fff;
}
.home-one .about .title,
.solution .center .title,
.productContiner .center .title,
.newsContiner .center .title,
.contactContiner .center .title{
	color: #fff;
}
.home-one .about p{
	text-indent: 2em;
}
.home-one .about a{
	text-align:center;
	display:block;
	border:solid 1px #fff;
	color: #fff;
}
.home-one .aboutNav{
	float: right;
	background-image: url(../../img/about_dh.png);
	background-size: 100% 100%;
	text-align: center;
}
.home-one .aboutNav .ryzz{
	text-align:center;
	display:inline-block;
	color: white;
}
.home-one .aboutNav .ryzz p{
	text-align:center;
}
.home-one .aboutNav .ryzz p a{
	color: #ffffff;
	text-align:center;
}
.home-one .aboutNav .ryzz p a:hover{
	color: #000000;
	text-align:center;
}
.home-one .aboutNav .ryzz span{
	text-align:center;
}
.solution_fenl ul{
	float: left;
	width:100%;
}
.solution_fenl ul li{
	list-style: none;
	display: inline-block;
	vertical-align: top;
}
.solution_fenl ul li div{
	border: 1px solid white;
}
.solution_fenl ul li p{
	color: white;
}
.solution_fenl ul li span{
	height: 0;
	display: block;
	padding: 0;
	margin: 0 auto;
	border: #0079e8 solid 1px;
}
.solution_next{
	float: left;
}
/* 产品 */
.product{
	width: 100%;
	display:block;
	text-align: left;
}
.product .main{
	background: white;
	color: #000000;
	display: inline-block;
	vertical-align: top;
	padding: 0 0;
	text-align: center;
}
.product .main h5,
.product .main p{
	color: #000000;
	text-align: left;
}

/* 联系我们 */

.contact{
	width:100%;
	text-align: center;
	color: #fff;
}
.contact .map{
	width:100%;
	height:5rem;
	margin-top: 0.6rem;
	padding: 0rem 0rem !important;
	border: 1px solid #fff;
}
.contact .address{
	height: 5rem;
	width: 100%;
	background-color:rgba(255,255,255,0.3);
	border: 1px solid white;
	float: left;
}
.contact .address .left,
.contact .address .right{	
	vertical-align: top;
	display: inline-block;
}
.contact .address .left{width: 80%;}
.contact .address .right{
	width:20%;
	padding-top:0.6rem;
	text-align:center !important;
}
.contact .address .left p{
	text-align: left;
	font-size: 0.6rem;
	font-weight: bold;
	margin: 0.4rem 0 0.4rem 1rem !important;
	line-height: 1rem !important;
}
.contact .address .left ul,
.contact .address .left ul li{
	list-style: none;
	text-align: left;
	padding: 0rem !important;
	margin-left: 0.6rem !important;
}
.contact .address .left ul li{
	line-height: 0.7rem !important;
}
.contact .address .left img{
	margin:0rem 0.4rem 0rem 0rem !important;
}
.contact .address .right p{
	text-align: center !important;
	width: 100%;
	margin: 0.6rem 0;
}
#root{
	width:100%;
	height:100%;
	color: #000;
}
/* 子页面通用样式 header */
.subpage{
	width: 100%;
	height: 100%;
	background: #fff;
	color: #000;
	padding: 0;
	margin: 0;
}
/* section */
.subpage .section,
.subpage .interlace-section{
	width: 100%;
	margin: 0 auto;
	display: block;
}
.subpage .section-title{
	margin: 0 auto;
	text-align: center;
	border-bottom: solid 3px #1e65c1;
}
.subpage .interlace-section{   
	background: #e3ecf5;
}

@media screen and (min-width:640px) {
	.newsContiner .news ul .small-next{
		display: none;
	}
}	
@media screen and (max-width:640px) {
	.newsContiner .news .choose .choose-left{left: 0;}
	.newsContiner .news .choose .choose-right{right: 0;}
	.home-layout{
		height: 100%;
	}
	.solution .center,
	.productContiner .center,
	.newsContiner .center,
	.contactContiner .center,
	.home-one{
		width: 100%;
		padding:1.8rem 0.7rem 0 0.7rem;
	}
	.home-one .about .title,
	.solution .center .title,
	.productContiner .center .title,
	.newsContiner .center .title,
	.contactContiner .center .title{
		font-size:0.3rem;
		margin:0.4rem 0rem 0rem 0rem;
	}
	/* 背景 */
	.home-main,
	.solution,
	.newsContiner,
	.productContiner,
	.contactContiner{
		height:100%;
	}
	/* 首页右侧 */
	.home-change{
		top:30%;
		right: 5%;
		width: 20px;
	}
	.home-change span{
		width: 14px;
		height: 14px;
		margin: 40px 0;
	}
	.home-change .active{
		width:14px;
		height:14px;
		margin: 40px 0;
	}
	/* 首页 */
	.home-one .about p,
	.solution .center p{
		line-height: 0.5rem;
		width: 100%;
	}
	.home-main{
		height: 100%;
	}
	.home-main .home-image{
		padding-top:6rem;
		width: 100%;
	}
	/* 解决方案 */
	.home-layout .solution {
		background-image: url(../../img/Solution_bj.jpg);
		background-size: cover;
		height: 100%;
	}
	.home-one{
		height: 13rem;
	}
	.home-one .about a{
		margin-top: 2%;
	}
	.home-one .aboutNav{
		display: none;
	}
	.solution_fenl{
		width: 100%;
		margin:1% auto;
	}
	.solution_fenl ul{
		width:100%;
		padding: 0;
	}
	.solution_fenl ul li{
		width:38%;
		margin: 0 1%;
	}
	.solution_fenl ul li img{
		width: 100%;
		height: 100%;
	}
	.solution_fenl ul li p{
		font-size: 0.26rem;
		line-height:0.8rem;
	}
	.solution_fenl ul li span{
		display: none;
	}
	.solution_next{
		display: none;
	}
	/* 产品 */
	.productContiner {
		background-image: url(../../img/product_bj.jpg);
		background-size: cover;
		height: 13rem;
	}
	.product{
		margin-top: 0.3rem;
	}
	.product .main{
		width:100%;
		margin-bottom: 0.4rem;
		padding: 0.1rem;
	}
	.product .main img{
		width:98%;
		height:2rem;
	}
	.product .main h5{
		font-size: 0.3rem;
		line-height: 0.6rem;
		margin: 0;
		color: #444;
	}
	.product .main p{
		line-height: 0.4rem;
		font-size: 0.23rem;
		color: #444;
	}
	/* 新闻中心 */
	.newsContiner .news{
		width: 100%;
		text-align: center;
		margin-top: 0.2rem;
	}
	.newsContiner .news .news-tab{
		width: 100%;
		margin: 0;
		display: block;
		margin-bottom: 0.4rem;
	}
	.newsContiner .news ul{
		margin: 0.8rem 0;
		padding: 0;
	}
	.newsContiner .news ul li{
		display: inline-block;
		vertical-align: middle;
	}
	.newsContiner .news ul .next_left,
	.newsContiner .news ul .next_right{
		list-style: none;
		width: 2%;
		height: 1rem;
		line-height: 1rem;
		border: 1px solid white;
	}
	.newsContiner .news ul .next_left{
		margin-right: 1%;
	}
	.newsContiner .news ul .next_right{
		margin-left: 1%;
	}
	.next_left:hover,
	.next_right:hover{
		color: #4698df;
	}
	.newsContiner .news .choose{
		position: relative;
		width: 100%;
		margin-bottom: 0.6rem;
		margin:0;
		list-style: none;
		padding: 0;
		background: #fff;
	}
	.newsContiner .news .choose .news-title{
		width: 100%;
		text-align: left;
		line-height: 0.8rem;
		padding-left: 0.45rem;
		margin-bottom: 0.2rem;
		background: #0079e8;
		font-size: 0.3rem;
		color: #fff;
	}
	.newsContiner .news .choose p,
	.newsContiner .news .choose h5{
		margin: 0;
		text-align: left;
		padding: 0 0.6rem;
		line-height: 0.6rem;
		color:#000 !important;
		font-size: 0.26rem;
		overflow: hidden;
		white-space: nowrap;
		text-overflow:ellipsis;
	}
	.newsContiner .news .choose .time{
		margin-top: 0.3rem;
		color:#000 !important;
		text-align: right;
		margin-right: 4%;
		padding-bottom: 0.2rem;
		font-size: 0.26rem !important;
	}
	/* 联系我们 */
	.contactContiner{
		height: 13rem;
		background-image: url(../../img/contact_bj.jpg);
		background-size: cover;
	}
	.contact .map{
		width:100%;
		height:2rem;
		margin-top: 0.6rem;
	}
	.contact .address{
		height: 6rem;
	}
	.contact .address .left{
		width: 100%;
		padding-left: 0.2rem;
	}
	.contact .address .right{
		width:100%;
		padding-top:0;
	}
	.contact .address .left p{
		font-size: 0.26rem;
		margin: 0 !important;
		line-height: 0.8rem !important;
	}
	.contact .address .left ul,
	.contact .address .left ul li{
		margin-left: 0rem !important;
	}
	.contact .address .left ul li{
		line-height: 0.5rem !important;
	}
	.contact .address .left img{
		margin:0rem 0.2rem 0rem 0rem !important;
	}
	.contact .address .right p{
		margin: 0.2rem 0;
	}
	/* section */
	.subpage .section-title{
		width: 1.8rem;
		min-width: 1.8rem;
		margin: 0 auto;
		margin-bottom: 4%;
		font-size: 0.35rem;
		border-bottom: solid 3px #1e65c1;
		line-height: 1rem;
	}
	.header_right .nav{
		font-size: 0.4rem;
	}
	/* 首页底部 */
	.home-left-footer{
		display: none;
	}
	.home-footer{
		display: none;
	}		
}
@media screen  and (min-width: 640px) and (max-width:1280px) {
	.newsContiner .news .choose .choose-left{left: 0;}
	.newsContiner .news .choose .choose-right{right: 0;}
	.home-layout{
		height: 100%;
	}
	/* 背景 */
	.home-one,
	.home-main,
	.solution,
	.newsContiner,
	.productContiner,
	.contactContiner{
		height:100%;
	}
	.home-one .about,
	.newsContiner .center,
	.solution .center,
	.contactContiner .center{
		width: 80%;
		padding-top:25%;
	}
	.productContiner .center{
		width: 80%;
		padding-top:35%;
	}
	.home-one .about .title,
	.solution .center .title,
	.productContiner .center .title,
	.newsContiner .center .title,
	.contactContiner .center .title{
		font-size:0.45rem;
		margin:0.4rem 0rem 0rem 1.6rem;
	}
	/* 首页右侧 */
	.home-change{
		top:30%;
		right: 5%;
		width: 20px;
	}
	.home-change span{
		width: 14px;
		height: 14px;
		margin: 40px 0;
	}
	.home-change .active{
		width:14px;
		height:14px;
		margin: 40px 0;
	}
	.home-main .home-image{
		margin-top:50%;
		width: 80%;
	}
	.home-one .about p,
	.solution .center p{
		line-height:0.7rem;
		margin-left:1.6rem;
		margin-top: 0.4rem;
	}
	.home-one .about p{
		width: 80%;
	}
	.solution .center p{
		width: 80%;
	}
	.home-one .about a{
		margin-left:1.6rem;
		margin-top: 2%;
		width:3.6rem;
		height:1rem;
		line-height:1rem;
	}
	.home-one .aboutNav{
		width: 68%;
		height: 3rem;
		padding-top: 0.7rem;
	}
	.home-one .aboutNav .ryzz img{
		width: 1rem;
		height: 0.9rem;
		margin-bottom: 0;
	}
	.home-one .aboutNav .ryzz p{
		width: 2.4rem;
		line-height: 0.8rem;
	}
	.home-one .aboutNav .ryzz span{
		width: 8rem;
	}
	/*解决方案*/
	.solution_fenl{
		width: 80%;
		margin:1% auto;
		height: 40%;
	}
	.solution_fenl ul{
		width:100%;
		padding: 0;
	}
	.solution_fenl ul li{
		width:40%;
		margin: 0 5%;
	}
	.solution_fenl ul li img{
		width: 100%;
		height: 100%;
	}
	.solution_fenl ul li p{
		font-size: 0.4rem;
		line-height:0.8rem;
		margin-top: 0.4rem;
	}
	.solution_fenl ul li span{
		width: 1rem;
	}
	.solution_next{
		width: 80px;
		height: 40px;
		margin-top: 15px;
		font-size: 25px;
		margin-left: 47%;
	}
	/* 产品 */
	.product{
		margin-top: 0.4rem;
	}
	.product .main{
		width:49%;
	}
	.product .main:first-child{
		margin-right: 2%;
	}
	.product .main img{
		width:98%;
		height:3.6rem;
		padding: 1% 0;
	}
	.product .main h5,
	.product .main p{
		margin-left: 0.4rem;
	}
	.product .main h5{
		font-size: 0.45rem;
	}
	.product .main p{
		font-size: 0.35rem;
	}
	/* 新闻中心 */
	.newsContiner .news{
		width: 100%;
		text-align: center;
		margin-top: 2rem;
	}
	.newsContiner .news .news-tab{
		width: 45%;
		margin: 0 2%;
		display: inline-block;
		vertical-align: top;
	}
	.newsContiner .news ul{
		margin: 0.8rem 0;
		padding: 0;
	}
	.newsContiner .news ul li{
		display: inline-block;
		vertical-align: middle;
	}
	.newsContiner .news ul .next_left,
	.newsContiner .news ul .next_right{
		list-style: none;
		width: 2%;
		height: 1rem;
		line-height: 1rem;
		border: 1px solid white;
	}
	.newsContiner .news ul .next_left{
		margin-right: 1%;
	}
	.newsContiner .news ul .next_right{
		margin-left: 1%;
	}
	.next_left:hover,
	.next_right:hover{
		color: #4698df;
	}
	.newsContiner .news .choose{
		width: 28.5%;
		min-height: 6.4rem;
		margin-bottom: 0.6rem;
		margin:0;
		list-style: none;
		padding: 0;
		background: #fff;
	}
	.newsContiner .news .choose .news-title{
		width: 70%;
		text-align: left;
		line-height: 1.2rem;
		padding-left: 0.45rem;
		margin-bottom: 0.6rem;
		background: #0079e8;
		font-size: 0.4rem;
		color: #fff;
	}
	.newsContiner .news .choose p,
	.newsContiner .news .choose h5{
		margin: 0;
		text-align: left;
		padding: 0 0.6rem;
		line-height: 1rem;
		color:#000 !important;
		font-size: 0.4rem;
		text-overflow: -o-ellipsis-lastline;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		line-clamp: 2;
		-webkit-box-orient: vertical;
	}
	.newsContiner .news .choose .time{
		margin-top: 0.3rem;
		color:#000 !important;
		text-align: right;
		margin-right: 4%;
		padding-bottom: 0.2rem;
		font-size: 0.4rem !important;
	}
	/* 联系我们 */
	.contact .map{
		width:100%;
		height:5rem;
		margin-top: 0.6rem;
	}
	.contact .address{
		height: 5rem;
	}
	.contact .address .left{
		width: 80%;
	}
	.contact .address .right{
		width:20%;
		padding-top:0.6rem;
	}
	.contact .address .left p{
		font-size: 0.6rem;
		margin: 0.4rem 0 0.4rem 1rem !important;
		line-height: 1rem !important;
	}
	.contact .address .left ul,
	.contact .address .left ul li{
		margin-left: 0.6rem !important;
	}
	.contact .address .left ul li{
		line-height: 0.7rem !important;
	}
	.contact .address .left img{
		margin:0rem 0.4rem 0rem 0rem !important;
	}
	.contact .address .right p{
		margin: 0.6rem 0;
	}
	/* section */
	.subpage .section-title{
		width: 3rem;
		margin-bottom: 4%;
		font-size: 0.65rem;
		line-height: 1.6rem;
	}
	.header_right .nav{
		font-size: 0.4rem;
	}
	/* 首页底部 */
	.home-left-footer{
		width:40%;
		font-size:0.35rem;
		bottom: 1.2rem;
		padding: 0.4rem 0 0 0;
	}
	.home-left-footer .icon{
		margin:0 0.4rem;
	}
	.home-left-footer .active{
		font-size:0.4rem;
		height: 1rem;
		line-height: 1rem;
	}
	.home-footer{
		bottom: 0;
		height: 1rem;
		line-height: 1rem;
	}	
}
@media screen  and (min-width: 1280px) and (max-width:1600px) {
	.newsContiner .news .choose .choose-left{left: 0;}
	.newsContiner .news .choose .choose-right{right: 0;}
	/* 背景 */
	.home-one,
	.home-main,
	.solution,
	.newsContiner,
	.productContiner,
	.contactContiner{
		height:100%;
	}
	.home-one .about,
	.newsContiner .center,
	.solution .center,
	.productContiner .center,
	.contactContiner .center{
		width: 80%;
		padding-top:15%;
	}
	.home-layout{
		height: 100%;
	}
	/* 首页右侧 */
	.home-change{
		top:30%;
		right: 5%;
		width: 20px;
	}
	.home-change span{
		width: 14px;
		height: 14px;
		margin: 40px 0;
	}
	.home-change .active{
		width:14px;
		height:14px;
		margin: 40px 0;
	}
	.home-main .home-image{
		margin-top:28%;
	}
	.home-one .about p,
	.solution .center p{
		line-height:0.7rem;
		margin-left:1.6rem;
		margin-top: 0.4rem;
	}
	.home-one .about p{
		width: 80%;
	}
	.solution .center p{
		width: 100%;
	}
	.home-one .about .title,
	.solution .center .title,
	.productContiner .center .title,
	.newsContiner .center .title,
	.contactContiner .center .title{
		font-size:0.45rem;
		margin:0.4rem 0rem 0rem 1.6rem;
	}
	.home-one .about a{
		margin-left:1.6rem;
		margin-top: 2%;
		width:3.6rem;
		height:1rem;
		line-height:1rem;
	}
	.home-one .aboutNav{
		width: 68%;
		height: 3rem;
		padding-top: 0.8rem;
	}
	.home-one .aboutNav .ryzz img{
		width: 1rem;
		height: 0.9rem;
		margin-bottom: 0;
	}
	.home-one .aboutNav .ryzz p{
		width: 3rem;
		margin-bottom: 0;
		line-height: 1.1rem;
	}
	.home-one .aboutNav .ryzz span{
		width: 8rem;
	}
	/*解决方案*/
	.solution_fenl{
		width: 80%;
		margin:1% auto;
		height: 40%;
	}
	.solution_fenl ul{
		width:100%;
	}
	.solution_fenl ul li{
		width:18%;
		margin: 0 1%;
	}
	.solution_fenl ul li img{
		width: 100%;
		height: 100%;
	}
	.solution_fenl ul li p{
		font-size: 0.4rem;
		line-height:0.8rem;
		margin-top: 0.4rem;
	}
	.solution_fenl ul li span{
		width: 1rem;
	}
	.solution_next{
		width: 80px;
		height: 40px;
		margin-top: 15px;
		font-size: 25px;
		margin-left: 47%;
	}
	/* 产品 */
	.product{
		margin-top: 0.4rem;
	}
	.product .main{
		width:49%;
	}
	.product .main:first-child{
		margin-right: 2%;
	}
	.product .main img{
		width:98%;
		height:7rem;
		padding: 1% 0;
	}
	.product .main h5,
	.product .main p{
		margin-left: 0.8rem;
	}
	.product .main h5{
		font-size: 0.45rem;
	}
	.product .main p{
		font-size: 0.35rem;
	}
	/* 新闻中心 */
	.newsContiner .news{
		width: 100%;
		text-align: center;
		margin-top: 2rem;
	}
	.newsContiner .news .news-tab{
		width: 45%;
		margin: 0 2%;
		display: inline-block;
		vertical-align: top;
	}
	.newsContiner .news ul{
		margin: 0.8rem 0;
		padding: 0;
	}
	.newsContiner .news ul li{
		display: inline-block;
		vertical-align: middle;
	}
	.newsContiner .news ul .next_left,
	.newsContiner .news ul .next_right{
		list-style: none;
		width: 2%;
		height: 1rem;
		line-height: 1rem;
		border: 1px solid white;
	}
	.newsContiner .news ul .next_left{
		margin-right: 1%;
	}
	.newsContiner .news ul .next_right{
		margin-left: 1%;
	}
	.next_left:hover,
	.next_right:hover{
		color: #4698df;
	}
	.newsContiner .news .choose{
		width: 28.5%;
		min-height: 6.4rem;
		margin-bottom: 0.6rem;
		margin:0;
		list-style: none;
		padding: 0;
		background: #fff;
	}
	.newsContiner .news .choose .news-title{
		width: 30%;
		text-align: left;
		line-height: 1.2rem;
		padding-left: 0.45rem;
		margin-bottom: 0.6rem;
		background: #0079e8;
		font-size: 0.4rem;
		color: #fff;
	}
	.newsContiner .news .choose p,
	.newsContiner .news .choose h5{
		margin: 0;
		text-align: left;
		padding: 0 0.6rem;
		line-height: 1rem;
		color:#000 !important;
		font-size: 0.4rem;
		text-overflow: -o-ellipsis-lastline;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		line-clamp: 2;
		-webkit-box-orient: vertical;
	}
	.newsContiner .news .choose .time{
		margin-top: 0.3rem;
		color:#000 !important;
		text-align: right;
		margin-right: 4%;
		padding-bottom: 0.2rem;
		font-size: 0.4rem !important;
	}
	/* 联系我们 */
	.contact .map{
		width:100%;
		height:5rem;
		margin-top: 0.6rem;
	}
	.contact .address{
		height: 5rem;
	}
	.contact .address .left{
		width: 80%;
	}
	.contact .address .right{
		width:20%;
		padding-top:0.6rem;
	}
	.contact .address .left p{
		font-size: 0.6rem;
		margin: 0.4rem 0 0.4rem 1rem !important;
		line-height: 1rem !important;
	}
	.contact .address .left ul,
	.contact .address .left ul li{
		margin-left: 0.6rem !important;
	}
	.contact .address .left ul li{
		line-height: 0.7rem !important;
	}
	.contact .address .left img{
		margin:0rem 0.4rem 0rem 0rem !important;
	}
	.contact .address .right p{
		margin: 0.6rem 0;
	}
	/* section */
	.subpage .section-title{
		width: 2.4rem;
		margin-bottom: 2%;
		font-size: 0.55rem;
		line-height: 1.6rem;
	}
	.header_right .nav{
		font-size: 0.4rem;
	}
	/* 首页底部 */
	.home-left-footer{
		width:20%;
		font-size:0.35rem;
		bottom: 1.2rem;
		padding: 0.4rem 0 0 0;
	}
	.home-left-footer .icon{
		margin:0 0.4rem;
	}
	.home-left-footer .active{
		font-size:0.4rem;
		height: 1rem;
		line-height: 1rem;
	}
	.home-footer{
		bottom: 0.4rem;
		height: 0.6rem;
		line-height: 1.4rem;
	}
}
@media screen  and (min-width: 1600px){
	.newsContiner .news .choose .choose-left{left: 0.2rem;}
	.newsContiner .news .choose .choose-right{right: 0.2rem;}
	.home-layout{
		height: 100%;
	}
	/* 首页右侧 */
	.home-change{
		top:30%;
		right: 5%;
		width: 20px;
	}
	.home-change span{
		width: 14px;
		height: 14px;
		margin: 40px 0;
	}
	.home-change .active{
		width:14px;
		height:14px;
		margin: 40px 0;
	}
	.home-one .about,
	.solution .center,
	.productContiner .center,
	.newsContiner .center,
	.contactContiner .center{
		width: 66%;
		padding-top:11%;
	}
	.home-main .home-image{
		margin-top:20%;
	}
	.home-one .about p,
	.solution .center p{
		width: 70%;
		line-height:0.7rem;
		margin-left:1.6rem;
		margin-top: 0.4rem;
	}
	.home-one .about .title,
	.solution .center .title,
	.productContiner .center .title,
	.newsContiner .center .title,
	.contactContiner .center .title{
		font-size:0.45rem;
		margin:0.4rem 0rem 0rem 1.6rem;
	}
	.home-one .about a{
		margin-left:1.6rem;
		margin-top: 2%;
		width:3.6rem;
		height:1rem;
		line-height:1rem;
	}
	.home-one .aboutNav{
		width: 68%;
		height: 3.6rem;
		margin-top: 1rem;
		padding-top: 0.9rem;
	}
	.home-one .aboutNav .ryzz img{
		width: 0.9rem;
		height: 0.9rem;
		margin-bottom: 0.4rem;
	}
	.home-one .aboutNav .ryzz p{
		width: 4rem;
		line-height: 0.8rem;
	}
	.home-one .aboutNav .ryzz span{
		width: 8rem;
	}
	/*解决方案*/
	.solution_fenl{
		width: 70%;
		margin:1% auto;
		height: 40%;
	}
	.solution_fenl ul{
		width:100%;
	}
	.solution_fenl ul li{
		width:18%;
		margin: 0 1%;
	}
	.solution_fenl ul li div{
		width: 100%;
		height: 4rem;
		overflow: hidden;
	}
	.solution_fenl ul li div img{
		width: 100%;
		height: 100%;
		transition: 0.4s ease;
	}
	.solution_fenl ul li:hover>div img{
		transform: scale(1.2);
		-moz-transform: scale(1.2);
		-webkit-transform: scale(1.2);
		-ms-transform: scale(1.2);
	}
	.solution_fenl ul li p{
		font-size: 0.4rem;
		line-height:0.8rem;
		margin-top: 0.4rem;
	}
	.solution_fenl ul li span{
		width: 1rem;
	}
	.solution_next{
		width: 80px;
		height: 40px;
		margin-top: 15px;
		font-size: 25px;
		margin-left: 47%;
	}
	/* 产品 */
	.product{
		margin-top: 0.4rem;
	}
	.product .main{
		width:49%;
	}
	.product .main:first-child{
		margin-right: 2%;
	}
	.product .main img{
		width:98%;
		height:7rem;
		padding: 1% 0;
	}
	.product .main h5,
	.product .main p{
		margin-left: 0.8rem;
	}
	.product .main h5{
		font-size: 0.45rem;
	}
	.product .main p{
		font-size: 0.35rem;
	}
	/* 新闻中心 */
	.newsContiner .news{
		width: 100%;
		text-align: center;
		margin-top: 2rem;
	}
	.newsContiner .news .news-tab{
		width: 45%;
		margin-left: 2%;
		display: inline-block;
		vertical-align: top;
	}
	.newsContiner .news ul{
		margin: 0.8rem 0;
		padding: 0;
	}
	.newsContiner .news ul li{
		display: inline-block;
		vertical-align: middle;
	}
	.newsContiner .news ul .next_left,
	.newsContiner .news ul .next_right{
		list-style: none;
		width: 2%;
		height: 1rem;
		line-height: 1rem;
		border: 1px solid white;
	}
	.newsContiner .news ul .next_left{
		margin-right: 1%;
	}
	.newsContiner .news ul .next_right{
		margin-left: 1%;
	}
	.next_left:hover,
	.next_right:hover{
		color: #4698df;
	}
	.newsContiner .news .choose{
		margin: 0;
		padding:0;
		background: #fff;
	}
	.newsContiner .news .choose .news-title{
		width: 30%;
		text-align: left;
		line-height: 1.2rem;
		padding-left: 0.45rem;
		margin-bottom: 0.6rem;
		background: #0079e8;
		font-size: 0.4rem;
		color: #fff;
	}
	.newsContiner .news .choose p,
	.newsContiner .news .choose h5{
		margin: 0;
		text-align: left;
		padding: 0 1rem 0 1.4rem;
		line-height: 1rem;
		color:#000 !important;
		font-size: 0.4rem;
		text-overflow: -o-ellipsis-lastline;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 1;
		line-clamp: 1;
		-webkit-box-orient: vertical;
	}
	.newsContiner .news .choose .time{
		color:#000 !important;
		text-align: right;
		padding: 0.3rem 2rem 0.6rem 0.2rem;
		font-size: 0.4rem !important;
	}
	/* 联系我们 */
	.contact .map{
		width:100%;
		height:5rem;
		margin-top: 0.6rem;
	}
	.contact .address{
		height: 5rem;
	}
	.contact .address .left{
		width: 80%;
	}
	.contact .address .right{
		width:20%;
		padding-top:0.6rem;
	}
	.contact .address .left p{
		font-size: 0.6rem;
		margin: 0.4rem 0 0.4rem 1rem !important;
		line-height: 1rem !important;
	}
	.contact .address .left ul,
	.contact .address .left ul li{
		margin-left: 0.6rem !important;
	}
	.contact .address .left ul li{
		line-height: 0.7rem !important;
	}
	.contact .address .left img{
		margin:0rem 0.4rem 0rem 0rem !important;
	}
	.contact .address .right p{
		margin: 0.6rem 0;
	}
	/* section */
	.subpage .section-title{
		width: 3rem;
		margin-bottom: 4%;
		font-size: 0.75rem;
		line-height: 1.6rem;
	}
	.header_right .nav{
		font-size: 0.4rem;
	}
	/* 首页底部 */
	.home-left-footer{
		width:14%;
		font-size:0.4rem;
		bottom: 1.2rem;
		padding: 0.4rem 0 0 0;
	}
	.home-left-footer .icon{
		margin:0 0.4rem;
	}
	.home-left-footer .active{
		font-size:0.5rem;
		height: 1rem;
		line-height: 1rem;
	}
	.home-footer{
		bottom: 0.6rem;
		height: 0.6rem;
		line-height: 1.4rem;
	}
	.home-main,
	.home-one,
	.solution,
	.productContiner,
	.newsContiner,
	.contactContiner{
		height: 100%;
	}
}
.all-footer{
    background: #000;
}
.all-footer .all{
    margin: 0 auto;
}
.all-footer .all .left,
.all-footer .all .right{
    height: 6rem;
    display: inline-block;
    vertical-align: top;
}
.all-footer .all .left{
    color: #6b6b6b;
    width: 85%;
    padding-top: 1rem;
}
.all-footer .all .left h5,
.all-footer .all .left p{
    font-size: 0.5rem;
    line-height: 0.6rem;
}
.all-footer .all .left h5{
    color: #fff;
    font-weight: bold;
    line-height: 1rem;
}
.all-footer .all .right{ 
    width: 15%;
    text-align: center;
    color: #fff;
    font-size: 0.5rem;
    font-weight: bold;
    padding-top: 1.6rem;
}
.all-footer .all .bottom{
    width: 100%;
    text-align: center;
    padding: 0.4rem 0;
    border-top: solid 1px #6b6b6b;
}
.all-footer .all .bottom p{
    line-height: 0.6rem;
    margin: 0;
    color: #6b6b6b;
}
@media screen and (max-width:640px) {
	.all-footer .all{
		width: 80%;
    }
    .all-footer .all .left,
    .all-footer .all .right{
        display: inline-block;
        vertical-align: top;
        border-bottom: solid 1px #6b6b6b;
    }
    .all-footer .all .left{
        color: #6b6b6b;
        width: 100%;
        padding-top: 0.3rem;
        height: 3.5rem;
    }
    .all-footer .all .left h5,
    .all-footer .all .left p{
        font-size: 0.26rem;
        line-height: 0.36rem;
    }
    .all-footer .all .left h5{
        color: #fff;
        font-weight: bold;
        line-height: 0.6rem;
    }
    .all-footer .all .right{ 
        width: 100%;
        text-align: center;
        color: #fff;
        font-size: 0.3rem;
        font-weight: bold;
        padding-top: 0.4rem;
        height: 2.6rem;
    }
    .all-footer .all .bottom{
        width: 100%;
        text-align: center;
        padding: 0.1rem 0;
        border-top: none;
    }
    .all-footer .all .bottom p{
        line-height: 0.4rem;
        font-size: 0.26rem;
        margin: 0;
        color: #6b6b6b;
    }
}
@media screen and (min-width:640px) and (max-width:1280px) {
    .all-footer .all{
		width: 90%;
	}
    .all-footer .all .bottom,
    .all-footer .all .left h5, 
    .all-footer .all .left p{
        font-size: 0.4rem;
        line-height: 0.6rem;
	}
    .all-footer .all .right{ 
        font-size: 0.3rem;
        font-weight: bold;
    }
    .all-footer .all .left,
    .all-footer .all .right{
        height: 4.5rem;
        display: inline-block;
        vertical-align: top;
        border-bottom: solid 1px #6b6b6b;
    }
}
@media screen and (min-width:1280px){
    .all-footer .all{
		width: 68%;
	}
    .all-footer .all .left h5{
        line-height: 1rem;
    }
    .all-footer .all .bottom,
    .all-footer .all .left p{
        font-size: 0.4rem;
        line-height: 0.4rem;
    }
    .all-footer .all .right{
        padding-top: 1.6rem;
    }
}
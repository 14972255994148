.subpage .nodeItem{
    width: 65%;
    margin: 0 auto;
    display: flex;
    padding: 1rem 0;
}
.subpage .nodeItem .left{
    width: 25%;
    display: block;
}
.subpage .nodeItem .right{
    width: 75%;
    border-left: 1px solid #e7e7e7;
    display: block;
    padding-left: 2%;
}
.subpage .nodeItem .left .title{
    display: block;
    line-height: 0.6rem;
    padding-left: 0.4rem;
    font-size: 0.4rem;
    margin: 0.4rem 0;
    border-left: solid 0.1rem #0079e8;
}
.subpage .nodeItem .left .bg,
.subpage .nodeItem .left .bg1{
    width: 96%;
    padding: 0;
    padding-left: 3%;
    display: block;
    border-radius: 5px;
    margin-bottom: 0.3rem;
    background: #f1f1f1;
    border: 1px solid #e7e7e7;
}
.subpage .nodeItem .left .bg1{
    padding: 6% 0 0 6%;
}
.subpage .nodeItem .left .bg p{
    line-height: 0.8rem;
    margin: 0;
    cursor: pointer;
}
.subpage .nodeItem .left .bg p:hover{
    color: cornflowerblue;
}
.subpage .nodeItem .left div  p img{
    margin-right: 0.3rem;
}
.subpage .nodeItem .left div  p .hidden{
    visibility:hidden;
}
.subpage .nodeItem .right .top{
    width: 100%;
    padding: 0 0 1rem 0; 
    display: flex;
}
.subpage .nodeItem .right .top .left{
    width: 34%;
}
.subpage .nodeItem .right .top .right{
    width: 66%;
    border-left: none;
}
.subpage .nodeItem .right .top .right h5{
    font-size: 0.7rem;
    line-height: 2.6rem;
}
.subpage .nodeItem .right .top .right p{
    font-size: 0.4rem;
    line-height: 0.8rem;
    margin: 0;
}
.subpage .nodeItem .right .top .right .button{
    margin-top: 1rem;
}
.subpage .nodeItem .right .top .right p button{
    color: #fff;
    border-radius: 0.2rem;
    font-size: 0.4rem;
    text-align: center;
    border: none;
    outline-style: none;
    padding: 0.1rem 0.8rem;
    margin-right: 3%;
    cursor: pointer;
}
.subpage .nodeItem .right .top .right p button img{
    margin-right: 0.4rem;
}
.subpage .nodeItem .right .top .right .bug{
    background: #ff8400;
}
.subpage .nodeItem .right .top .right .consult{
    background: #0079e8;
}
.subpage .nodeItem .right .bottom{
    width: 100%;
    padding: 0;
    margin:0 ;
    list-style: none;
}
.subpage .nodeItem  .bottom li{
    width: 100%;
    line-height: 1rem;
}
.subpage .nodeItem  .bottom li h5,
.subpage .nodeItem  .bottom li span{
    display: inline-block;
    text-align: center;
    width: 50%;
    border-bottom:1px solid #e7e7e7;
    border-left:1px solid #e7e7e7;
    margin: 0;
}
.subpage .nodeItem  .bottom li h5{
    font-weight: bold;
    font-size: 0.5rem;
    border-top:1px solid #e7e7e7;
}
.subpage .nodeItem  .bottom li span:last-child,
.subpage .nodeItem  .bottom li h5:last-child{
    border-right:1px solid #e7e7e7;
}
.subpage .nodeItem .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.subpage .nodeItem .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active{
    background: #0079e8;
    color: #fff !important;
}
.subpage .nodeItem  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab{
    margin-right: 0.4rem !important;
    padding: 0.1rem 1rem;
}
.subpage .nodeItem .TabPane img{
    width: 30%;
    display: inline-block;
    vertical-align: top;
}
.subpage .nodeItem .right .top .left img{
    width: 100%;
}
.subpage .bg2{
    display: none;
}
@media screen  and (max-width:640px){
    .subpage .nodeItem {
        width: 100%;
        margin: 0 auto;
        display: block;
        padding: 0.4rem;
    }
    .subpage .nodeItem .left {
        width: 100%;
        display: block;
    }
    .subpage .nodeItem .right {
        width: 100%;
        border-left: none;
        display: block;
        padding-left: 0;
    }
    .subpage .nodeItem .right .top {
        width: 100%;
        padding: 0 0 0.4rem 0;
        display: block;
    }
    .subpage .nodeItem .right .top .left {
        width: 100%;
    }
    .subpage .nodeItem .right .top .left img{
        width: 70%;
        margin: 0 15%;
    }
    .subpage .nodeItem .right .top .right {
        width: 100%;
        border-left: none;
    }
    .subpage .nodeItem .right .top .right h5 {
        font-size: 0.4rem;
        line-height: 0.5rem;
    }
    .subpage .nodeItem .right .top .right p {
        font-size: 0.3rem;
        line-height: 0.6rem;
        margin: 0;
    }
    .subpage .nodeItem .right .top .right p button {
        color: #fff;
        border-radius: 0.2rem;
        font-size: 0.24rem;
        text-align: center;
        border: none;
        outline-style: none;
        padding: 0.06rem 0.3rem;
        margin-top: 0.2rem;
    }
    .subpage .nodeItem .right .top .right p button img {
        display: none;
    }
    .subpage .nodeItem .right .top .right .button {
        margin-top: 0;
        text-align: center;
    }
    .subpage .nodeItem .right .top .right p {
        font-size: 0.26rem;
        line-height: 0.6rem;
        margin: 0;
        margin-top: 0px;
    }
    .subpage .nodeItem .bottom li {
        line-height: 0.8rem;
    }
    .subpage .nodeItem .bottom li h5 {
        font-weight: bold;
        font-size: 0.3rem;
        border-top: 1px solid #e7e7e7;
    }
    .subpage .nodeItem .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab{
        padding:0.1rem 0.3rem;
    }
    .subpage .nodeItem .TabPane img{
        width: 100%;
    }
    .subpage .bg2 {
        display: block;
    }
    .subpage .bg2  .title{
        font-size: 0.34rem;
        line-height: 0.4rem;
        margin-left: 5%;
        margin-bottom: 0.34rem;
        padding-left: 0.2rem;
        border-left: solid 0.1rem #0079e8;
    }
    .subpage .bg2 .bg1{
        display: block;
        width: 90%;
        margin: 0 auto;
        padding: 0;
        padding-left: 3%;
        padding-top: 3%;
        display: block;
        border-radius: 5px;
        margin-bottom: 0.3rem;
        background: #f1f1f1;
        border: 1px solid #e7e7e7;
    }
    .subpage .bg2 .bg1 p img{
        margin-right: 0.3rem;
    }
    .subpage .bg2 .bg1 p .hidden{
        visibility:hidden;
    }
    .subpage .nodeItem .left .bg1,
    .subpage .nodeItem .left .node-contact{
        display: none;
    }
}
.subpage .car-solution-header {
  background-image: url(../../img/banner4.jpg);
  background-size: 100% 100%;
}
.subpage .chemistry-solution-header {
  background-image: url(../../img/banner6.jpg);
  background-size: 100% 100%;
}
.subpage .electric-solution-header {
  background-image: url(../../img/banner8.jpg);
  background-size: 100% 100%;
}
.subpage .steel-solution-header {
  background-image: url(../../img/banner7.jpg);
  background-size: 100% 100%;
}
.subpage .section .solution-section img,
.subpage .section .solution-section ul {
  display: inline-block;
  vertical-align: top;
}
.subpage .section .solution-section ul {
  text-align: left;
  color: #0079e8;
}
.subpage .section .solution-section,
.subpage .interlace-section .solution-second-section,
.subpage .section .solution-third-section {
  margin: 0 auto;
  text-align: center;
}
.subpage .section .solution-section ul li span,
.subpage .section .solution-section ul li strong {
  color: black;
}
.subpage .interlace-section .solution-second-section ul,
.subpage .interlace-section .solution-second-section ul li {
  list-style: none;
  padding: 0;
  margin: 0;
}
.subpage .interlace-section .solution-second-section ul li {
  display: inline-block;
  vertical-align: top;
  background: #fff;
  list-style: none;
}
.subpage .interlace-section .solution-second-section ul li img,
.subpage .interlace-section .solution-second-section ul li p {
  display: inline-block;
  vertical-align: middle;
}
.subpage .section .solution-third-section img {
  width: 96%;
  margin: 0 auto;
  text-align: center;
}
.subpage .interlace-section .four-interlace-section {
  margin: 0 auto;
  text-align: left;
}
.subpage .interlace-section .four-interlace-section .ant-tabs > .ant-tabs-nav {
  margin: 0;
}
.subpage .interlace-section .four-interlace-section .ant-tabs-content-holder {
  background: #fff;
}
.subpage .interlace-section .four-interlace-section .img,
.subpage .interlace-section .four-interlace-section .ul {
  display: inline-block;
  vertical-align: top;
  text-align: left;
}
.subpage .interlace-section .four-interlace-section .img {
  text-align: center;
}
.subpage .interlace-section .four-interlace-section .ul {
  list-style: none;
  font-weight: 500;
}
.subpage .interlace-section .four-interlace-section .ul li h4 {
  font-weight: bold;
}
.subpage .interlace-section .four-interlace-section .ul li a {
  color: #fff;
  text-align: center;
  display: inline-block;
  background: #0079e8;
}
.subpage .section .customer-section img {
  display: inline-block;
  vertical-align: top;
  border: 1px solid #dcdcdc;
}
@media screen and (max-width: 640px) {
  .subpage .section,
  .subpage .interlace-section {
    line-height: 0.4rem;
  }
  .subpage .interlace-section .four-interlace-section .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
    background: none;
    border: solid 1px #fff;
    margin: 0;
    margin-right: -1px;
    font-weight: 500;
    font-size: 0.2rem;
  }
  .ant-tabs-left > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane,
  .ant-tabs-left > div > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
    margin: 0;
    padding: 0;
  }
  .subpage .interlace-section .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
  .subpage
    .interlace-section
    .four-interlace-section
    .ant-tabs-card
    > .ant-tabs-nav
    .ant-tabs-tab-active {
    background: #fff;
    color: #000 !important;
    line-height: 0.8rem;
  }
  .subpage .section .solution-section,
  .subpage .interlace-section .solution-second-section,
  .subpage .section .solution-third-section {
    width: 100%;
    padding: 0;
  }
  .subpage .section .solution-section img,
  .subpage .section .solution-section ul {
    min-height: 3rem;
  }
  .subpage .section .solution-section ul,
  .subpage .section .solution-section img {
    width: 90%;
    padding: 0;
  }
  .subpage .section .solution-section ul li {
    list-style: none;
    font-size: 0.23rem;
    margin-bottom: 0.1rem;
  }
  .subpage .section .solution-section ul li span,
  .subpage .section .solution-section ul li strong {
    line-height: 0.44rem;
  }
  .subpage .interlace-section .solution-second-section ul {
    padding-bottom: 3%;
  }
  .subpage .interlace-section .solution-second-section ul li {
    width: 48%;
    margin: 1% 1%;
    display: inline-block;
    vertical-align: top;
    background: #fff;
    list-style: none;
    min-height: 4.8rem;
    padding-top: 4%;
  }
  .subpage .interlace-section .solution-second-section ul li img,
  .subpage .interlace-section .solution-second-section ul li p {
    margin-right: 2%;
  }
  .subpage .interlace-section .solution-second-section ul li p {
    width: 80%;
    line-height: 0.4rem;
    text-align: left;
    margin: 2% 0;
  }
  .subpage .section .solution-third-section img {
    width: 96%;
    margin: 0 auto;
    text-align: center;
  }
  .subpage .interlace-section .four-interlace-section {
    width: 100%;
    text-align: left;
    padding-bottom: 0.4rem;
  }
  .subpage .interlace-section .four-interlace-section .ant-tabs > .ant-tabs-nav {
    margin: 0;
  }
  .subpage .interlace-section .four-interlace-section .ant-tabs-content-holder {
    background: #fff;
    padding: 0;
  }
  .subpage .interlace-section .four-interlace-section .img {
    width: 100%;
    text-align: center;
  }
  .subpage .interlace-section .four-interlace-section .img img {
    width: 80%;
  }
  .subpage .interlace-section .four-interlace-section .ul {
    width: 100%;
    list-style: none;
    font-size: 0.2rem;
    line-height: 0.4rem;
    font-weight: 500;
    padding: 0 5%;
  }
  .subpage .interlace-section .four-interlace-section .ul li h4 {
    font-size: 0.35rem;
    font-weight: bold;
    line-height: 0.4rem;
  }
  .subpage .interlace-section .four-interlace-section .ul li a {
    color: #fff;
    padding: 0.06rem 0.2rem;
    margin-top: 0.2rem;
    font-size: 0.2rem;
    text-align: center;
    display: inline-block;
    border-radius: 0.1rem;
    background: #0079e8;
  }
  .subpage .section .customer-section img {
    width: 18%;
    margin-right: 2%;
    display: inline-block;
    vertical-align: top;
    border: 1px solid #dcdcdc;
  }
}
@media screen and (min-width: 640px) and (max-width: 1280px) {
  .subpage .interlace-section .four-interlace-section .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
    background: none;
    border: solid 1px #fff;
    margin: 0;
    margin-right: -1px;
    font-weight: 500;
    font-size: 0.4rem;
  }
  .ant-tabs-left > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane,
  .ant-tabs-left > div > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
    margin: 0;
    padding: 3%;
  }
  .subpage .interlace-section .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
  .subpage
    .interlace-section
    .four-interlace-section
    .ant-tabs-card
    > .ant-tabs-nav
    .ant-tabs-tab-active {
    background: #fff;
    color: #000 !important;
    line-height: 0.8rem;
  }
  .subpage .section .solution-section,
  .subpage .interlace-section .solution-second-section,
  .subpage .section .solution-third-section {
    width: 100%;
    padding: 0;
  }
  .subpage .section .solution-section img {
    min-height: 2rem;
    margin-bottom: 3%;
  }
  .subpage .section .solution-section ul {
    min-height: 2rem;
    width: 90%;
    padding: 0;
  }
  .subpage .section .solution-section ul li {
    list-style: none;
    font-size: 0.38rem;
    margin-bottom: 0.1rem;
  }
  .subpage .section .solution-section ul li span,
  .subpage .section .solution-section ul li strong {
    line-height: 0.44rem;
  }
  .subpage .interlace-section .solution-second-section ul {
    padding-bottom: 3%;
  }
  .subpage .interlace-section .solution-second-section ul li {
    width: 48%;
    margin: 1% 1%;
    display: inline-block;
    vertical-align: top;
    background: #fff;
    list-style: none;
    min-height: 2.8rem;
    padding-top: 4%;
  }
  .subpage .interlace-section .solution-second-section ul li img,
  .subpage .interlace-section .solution-second-section ul li p {
    margin-right: 2%;
  }
  .subpage .interlace-section .solution-second-section ul li p {
    width: 80%;
    line-height: 0.4rem;
    text-align: left;
    margin: 2% 0;
  }
  .subpage .section .solution-third-section img {
    width: 96%;
    margin: 0 auto;
    text-align: center;
  }
  .subpage .interlace-section .four-interlace-section {
    width: 90%;
    text-align: left;
    padding-bottom: 0.4rem;
  }
  .subpage .interlace-section .four-interlace-section .ant-tabs > .ant-tabs-nav {
    margin: 0;
  }
  .subpage .interlace-section .four-interlace-section .ant-tabs-content-holder {
    background: #fff;
    padding: 0;
  }
  .subpage .interlace-section .four-interlace-section .img,
  .subpage .interlace-section .four-interlace-section .ul {
    display: inline-block;
    vertical-align: middle;
  }
  .subpage .interlace-section .four-interlace-section .img {
    width: 30%;
    text-align: center;
  }
  .subpage .interlace-section .four-interlace-section .img img {
    width: 100%;
    text-align: center;
  }
  .subpage .interlace-section .four-interlace-section .ul {
    width: 70%;
    list-style: none;
    font-size: 0.35rem;
    line-height: 0.8rem;
    font-weight: 500;
  }
  .subpage .interlace-section .four-interlace-section .ul li h4 {
    font-size: 0.6rem;
    font-weight: bold;
    line-height: 0.4rem;
  }
  .subpage .interlace-section .four-interlace-section .ul li a {
    color: #fff;
    padding: 0.06rem 0.2rem;
    margin-top: 0.2rem;
    font-size: 0.35rem;
    text-align: center;
    display: inline-block;
    border-radius: 0.1rem;
    background: #0079e8;
  }
  .subpage .section .customer-section img {
    width: 18%;
    margin-right: 2%;
    display: inline-block;
    vertical-align: top;
    border: 1px solid #dcdcdc;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1600px) {
  .subpage .section .solution-section,
  .subpage .interlace-section .solution-second-section,
  .subpage .section .solution-third-section {
    width: 90%;
    padding-bottom: 2%;
  }
  .subpage .section .solution-section img,
  .subpage .section .solution-section ul {
    min-height: 3rem;
  }
  .subpage .section .solution-section ul {
    width: 65%;
    padding: 0;
    padding-left: 3%;
  }
  .subpage .section .solution-section img {
    width: 32%;
    padding: 0;
  }
  .subpage .section .solution-section ul li {
    font-size: 0.4rem;
  }
  .subpage .section .solution-section ul li span,
  .subpage .section .solution-section ul p,
  .subpage .section .solution-section ul li strong {
    line-height: 0.8rem;
    color: #333;
  }
  .subpage .interlace-section .solution-second-section ul {
    padding-bottom: 2%;
  }
  .subpage .interlace-section .solution-second-section ul li {
    width: 48%;
    margin: 1% 1%;
    display: inline-block;
    vertical-align: top;
    background: #fff;
    list-style: none;
    min-height: 2.4rem;
    padding: 1% 0;
  }
  .subpage .interlace-section .solution-second-section ul li p {
    width: 80%;
    line-height: 0.4rem;
    text-align: left;
    margin: 2% 0;
  }
  .subpage .interlace-section .solution-second-section ul li img,
  .subpage .interlace-section .solution-second-section ul li p {
    margin-right: 2%;
  }
  .subpage .section .solution-third-section img {
    width: 96%;
    margin: 0 auto;
    text-align: center;
  }
  .subpage .interlace-section .four-interlace-section .ant-tabs > .ant-tabs-nav {
    margin: 0;
  }
  .subpage .interlace-section .four-interlace-section .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
    background: none;
    border: solid 1px #fff;
    margin: 0;
    margin-right: -1px;
    font-weight: 500;
    font-size: 0.35rem;
  }
  .subpage .interlace-section .four-interlace-section .ant-tabs-content-holder {
    background: #fff;
    padding: 1rem;
  }
  .subpage .interlace-section .four-interlace-section .img,
  .subpage .interlace-section .four-interlace-section .ul {
    display: inline-block;
    vertical-align: top;
    text-align: left;
    height: 4.8rem;
  }
  .subpage .interlace-section .four-interlace-section .img {
    width: 30%;
    text-align: center;
  }
  .subpage .interlace-section .four-interlace-section .ul {
    width: 70%;
    list-style: none;
    font-size: 0.4rem;
    line-height: 0.8rem;
    font-weight: 500;
  }
  .subpage .interlace-section .four-interlace-section .ul li h4 {
    font-size: 0.55rem;
    font-weight: bold;
    line-height: 1rem;
  }
  .subpage .interlace-section .four-interlace-section .ul li a {
    color: #fff;
    padding: 0 0.4rem;
    margin-top: 0.4rem;
    font-size: 0.4rem;
    text-align: center;
    display: inline-block;
    border-radius: 0.1rem;
    background: #0079e8;
  }
  .subpage .section .customer-section img {
    width: 18%;
    margin-right: 2%;
    display: inline-block;
    vertical-align: top;
    border: 1px solid #dcdcdc;
  }
  .subpage .interlace-section .solution-second-section ul li p {
    width: 80%;
    height: 1.4rem;
    line-height: 0.6rem;
    padding: 2% 0;
  }
  .subpage .section .solution-third-section img {
    width: 96%;
  }
  .subpage .interlace-section .four-interlace-section {
    width: 80%;
    margin-bottom: 0.8rem;
  }
  .subpage .interlace-section .four-interlace-section .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
    margin-right: -1px;
    font-size: 0.35rem;
  }
  .subpage .interlace-section .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
  .subpage
    .interlace-section
    .four-interlace-section
    .ant-tabs-card
    > .ant-tabs-nav
    .ant-tabs-tab-active {
    background: #fff;
    color: #000 !important;
    line-height: 0.8rem;
  }
  .subpage .interlace-section .four-interlace-section .ant-tabs-content-holder {
    padding: 1rem;
  }
  .subpage .interlace-section .four-interlace-section .img,
  .subpage .interlace-section .four-interlace-section .ul {
    height: 4.8rem;
  }
  .subpage .interlace-section .four-interlace-section .img {
    width: 30%;
  }
  .subpage .interlace-section .four-interlace-section .ul {
    width: 65%;
    margin-left: 3%;
    font-size: 0.4rem;
    line-height: 0.8rem;
  }
  .subpage .interlace-section .four-interlace-section .ul li h4 {
    font-size: 0.55rem;
    line-height: 1rem;
  }
  .subpage .interlace-section .four-interlace-section .ul li a {
    color: #fff;
    padding: 0 0.4rem;
    margin-top: 0.4rem;
    font-size: 0.4rem;
    border-radius: 0.1rem;
  }
  .subpage .section .customer-section img {
    width: 18%;
    margin-right: 2%;
  }
}
@media screen and (min-width: 1600px) {
  .subpage .section .solution-section,
  .subpage .interlace-section .solution-second-section,
  .subpage .section .solution-third-section {
    width: 62%;
    padding: 0;
  }
  .subpage .section .solution-section img,
  .subpage .section .solution-section ul {
    min-height: 3rem;
  }
  .subpage .section .solution-section ul {
    width: 65%;
    padding: 0;
    padding-left: 3%;
  }
  .subpage .section .solution-section img {
    width: 32%;
    padding: 0;
  }
  .subpage .section .solution-section ul p {
    color: #000;
    font-size: 0.4rem;
    line-height: 0.8rem;
  }
  .subpage .section .solution-section ul li {
    font-size: 0.4rem;
  }
  .subpage .section .solution-section ul li span,
  .subpage .section .solution-section ul li strong {
    line-height: 0.8rem;
  }
  /* 222 */
  .subpage .interlace-section .solution-second-section .solution-section-p {
    width: 80%;
    padding-bottom: 2%;
    margin: 0 auto;
    line-height: 0.8rem;
    font-size: 0.4rem;
  }
  .subpage .interlace-section .solution-second-section ul {
    padding-bottom: 3%;
  }
  .subpage .interlace-section .solution-second-section ul li {
    width: 48%;
    margin: 1% 1%;
    display: inline-block;
    vertical-align: top;
    background: #fff;
    list-style: none;
    min-height: 3.4rem;
    padding: 3% 0;
  }
  .subpage .interlace-section .solution-second-section ul li p {
    width: 80%;
    line-height: 0.4rem;
    text-align: left;
    margin: 2% 0;
    font-size: 0.4rem;
  }
  .subpage .interlace-section .solution-second-section ul li img,
  .subpage .interlace-section .solution-second-section ul li p {
    margin-right: 2%;
  }
  .subpage .section .solution-third-section img {
    width: 96%;
    margin: 0 auto;
    text-align: center;
  }
  .subpage .interlace-section .four-interlace-section {
    width: 64%;
    margin: 0 auto;
    text-align: left;
    margin-bottom: 0.8rem;
  }
  .subpage .interlace-section .four-interlace-section .ant-tabs > .ant-tabs-nav {
    margin: 0;
  }
  .subpage .interlace-section .four-interlace-section .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
    background: none;
    border: solid 1px #fff;
    margin: 0;
    margin-right: -1px;
    font-weight: 500;
    font-size: 0.4rem;
  }
  .subpage .interlace-section .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
  .subpage
    .interlace-section
    .four-interlace-section
    .ant-tabs-card
    > .ant-tabs-nav
    .ant-tabs-tab-active {
    background: #fff;
    color: #000 !important;
    line-height: 0.8rem;
    font-size: 0.4rem;
  }
  .subpage .interlace-section .four-interlace-section .ant-tabs-content-holder {
    background: #fff;
    padding: 1rem;
  }
  .subpage .interlace-section .four-interlace-section .img,
  .subpage .interlace-section .four-interlace-section .ul {
    display: inline-block;
    vertical-align: top;
    text-align: left;
    height: 4.8rem;
  }
  .subpage .interlace-section .four-interlace-section .img {
    width: 30%;
    text-align: center;
  }
  .subpage .interlace-section .four-interlace-section .ul {
    width: 70%;
    list-style: none;
    font-size: 0.4rem;
    line-height: 0.8rem;
    font-weight: 500;
  }
  .subpage .interlace-section .four-interlace-section .ul li h4 {
    font-size: 0.55rem;
    font-weight: bold;
    line-height: 1rem;
  }
  .subpage .interlace-section .four-interlace-section .ul li a {
    color: #fff;
    padding: 0 0.4rem;
    margin-top: 0.4rem;
    font-size: 0.4rem;
    text-align: center;
    display: inline-block;
    border-radius: 0.1rem;
    background: #0079e8;
  }
  .subpage .section .customer-section img {
    width: 18%;
    margin-right: 2%;
    display: inline-block;
    vertical-align: top;
    border: 1px solid #dcdcdc;
  }
  .subpage .interlace-section .solution-second-section ul li p {
    width: 80%;
    height: 1.4rem;
    line-height: 0.6rem;
    padding: 1% 0;
  }
  .subpage .section .solution-third-section img {
    width: 96%;
  }
  .subpage .interlace-section .four-interlace-section {
    width: 64%;
    margin-bottom: 0.8rem;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
  .subpage
    .interlace-section
    .four-interlace-section
    .ant-tabs-card
    > .ant-tabs-nav
    .ant-tabs-tab-active {
    line-height: 0.8rem;
  }
  .subpage .interlace-section .four-interlace-section .ant-tabs-content-holder {
    padding: 1rem;
  }
  .subpage .interlace-section .four-interlace-section .img,
  .subpage .interlace-section .four-interlace-section .ul {
    height: 4.8rem;
  }
  .subpage .interlace-section .four-interlace-section .img {
    width: 30%;
  }
  .subpage .interlace-section .four-interlace-section .img img {
    width: 100%;
  }
  .subpage .interlace-section .four-interlace-section .ul {
    width: 70%;
    font-size: 0.4rem;
    line-height: 0.8rem;
  }
  .subpage .interlace-section .four-interlace-section .ul li h4 {
    font-size: 0.55rem;
    line-height: 1rem;
  }
  .subpage .interlace-section .four-interlace-section .ul li a {
    color: #fff;
    padding: 0 0.4rem;
    margin-top: 0.4rem;
    font-size: 0.4rem;
    border-radius: 0.1rem;
  }
  .subpage .section .customer-section img {
    width: 18%;
    margin-right: 2%;
  }
}

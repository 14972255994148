.subpage .about-header{
	background-image: url(../../img/banner1.jpg);
	background-size: 100% 100%;
}
.subpage .about{
	margin: 0 auto;
	text-align: center;
}
.subpage .about .about-title{
	width: 100%;
	text-align: center;
	display: block;
}
.subpage .section .company .left,
.subpage .section .company .right{
	display: inline-block;
	vertical-align: top;
}
.subpage .section .company .right img{
	width: 100%;
}
.subpage .section .company .left{
	text-align: left;    
	background: #e3ecf4;
	padding:2%;
	margin:0 0 0.6rem 0;
}
.subpage .section .company .left p{
	margin: 0;
	text-indent: 2em;
}
.subpage .about-second-section{
	padding: 3% 0;
	width: 100%;
	text-align: center;
	background: url(../../img/enterprise_bj.jpg) no-repeat center center;
	background-size: 100% 100%;
}
.subpage .about-second-section .enterprise ul,
.subpage .section .growing ul,
.subpage .section .growing ul li,
.subpage .section .join ul,
.subpage .section .join ul li,
.subpage .about-second-section .enterprise ul li,
.subpage .about-second-section .enterprise ul li span{
	list-style: none;
	padding: 0;
	margin: 0;
}
.subpage .about-second-section .enterprise ul li{
	padding: 2%;
	background: #70a8db;
	border: 1px solid #227ed1;
	text-align: center;
	color: white;
}
.subpage .about-second-section .enterprise ul li p{  
	font-size: 0.5rem;
    font-weight: bold;
    line-height: 1.2rem;
	margin: 0;
	margin-top: 0.3rem;
}
.subpage .section .growing .top li{  
	padding: 0;
    margin: 0;
    display: inline-block;
    vertical-align: top;
    color: #333333;
    font-size: 0.45rem;
    padding: 1% 1rem;
    background: #e3ecf4;
    cursor: pointer;
    position: relative;
    font-weight: 400;
}
.subpage .section .growing .top li:last-child,
.subpage .section .growing .top li:first-child{  
	background: none;
}
.subpage .section .growing .top .active{
	background: #65aced;
	color: #fff;
}
.subpage .section .growing .top .active span{
	position: absolute;
	width: 0;
	height: 0;
	border-right: 0.2rem solid transparent;
	border-left: 0.2rem solid transparent;
	border-top: 0.4rem solid #65aced;
    color: #fff;
    bottom: -0.3rem;
    left: 45%;
}
.subpage .interlace-section .certificate .carousel div:last-child{ 
	margin-right: 0;
}
.interlace-section .certificate .carousel div img{ 
	width: 100%;
}
.subpage .section .join ul{
	width: 100%;
	margin: 0;
	padding: 0;
}
.subpage .section .join ul li{
	width: 100%;
	margin: 0;
	padding: 0;
	color: #666a75;
	text-align: left;
}
.subpage .section .join ul li span{
	width: 25%;
	display: inline-block;
	vertical-align: top;
	text-align: left;
	padding-left: 3%;
}
.subpage .section .join .ant-collapse > .ant-collapse-item {
	border-bottom: 1px solid #d9d9d9 !important;
}
.subpage .section .join ul .about-ul-first{
	background: url(../../img/join_bj.png) no-repeat center center;
	background-size: 100% 100%;
	height: 2rem;
	line-height: 2rem;
	font-size: 0.5rem;
	color: #fff;
	border-bottom: none;
}
.subpage .section .join ul li span:last-child{
	text-align: center;
}
.subpage .interlace-section .contact-us .component-map{
	width: 100%;
}
.subpage .interlace-section .contact-us .left,
.subpage .interlace-section .contact-us .right{	
	display: inline-block;
	vertical-align: top;
	background: #fff;
	padding-top: 0.4rem;
}
.subpage .interlace-section .contact-us .left{
	width: 85%;
	text-align: left;
	padding-left: 3%;
}
.subpage .interlace-section .contact-us .right{	
	width: 15%;
}
.subpage .interlace-section .contact-us .right p{	
	margin-top: 0.4rem;
}
.subpage .interlace-section .contact-us .left h5{
	margin-bottom: 0.3rem;
	font-size: 0.5rem;
	font-weight: bold;
	color: #000;
}
.subpage .interlace-section .contact-us .left p img{
	margin-right: 0.4rem;
}
@media screen and (min-width:640px) and (max-width:1280px){
	.subpage .about{width: 100%;}
	.subpage .about .about-title{margin-bottom: 0.5rem;}
	.subpage .section .company .left{width: 100%;}
	.subpage .section .company .left p{
		margin: 0;
		text-indent: 2em;
		line-height: 0.6rem;
	}	
	.subpage .section .company .right{width: 100%;}
	.subpage .about-second-section .enterprise ul li{
		width: 80%;
		min-height: 3.8rem;
		margin: 0.4rem auto;
	}
	.subpage .section .growing .alternate{
		padding:0 8%;
	}
	.subpage .section .growing .top{	
		margin-bottom: 0.6rem;
	}
	.subpage .section .growing .top li{  
		width: 100%;
	}
	.subpage .section .growing .top li:last-child,
	.subpage .section .growing .top li:first-child{  
		display: none;
	}
	.subpage .interlace-section .certificate .carousel div{ 
		display: inline-block;
		vertical-align: top;
		width: 17%;
		margin: 1% 1%;
	}
	.subpage .section .join ul .about-ul-first{
		background: url(../../img/join_bj.png) no-repeat center center;
		background-size: 100% 100%;
		height: 1rem;
		line-height: 1rem;
		font-size: 0.4rem;
		color: #fff;
		border-bottom: none;
	}
	.subpage .section .join ul li{
		width: 100%;
		margin: 0;
		padding: 0;
		color: #666a75;
		line-height: 0.4rem;
		text-align: left;
	}
	.subpage .section .join ul li{
		line-height: 1rem;
	}
	.subpage .interlace-section .contact-us .component-map{
		height: 5rem;
	}
	.subpage .about{
		padding: 1rem 0;
	}
	.subpage .interlace-section .contact-us .left p{
		font-size: 0.4rem;
		margin: 0;
	}
	.subpage .interlace-section .contact-us .left h5{
		margin-bottom: 0.3rem;
		font-size: 0.5rem;
		font-weight: bold;
		color: #000;
	}
	.subpage .interlace-section .contact-us .left, 
	.subpage .interlace-section .contact-us .right{
		min-height: 0rem;
		padding: 0.3rem;
		width: 100%;
		text-align: left;
	}
	.subpage .interlace-section .contact-us .right{
		display: none;
	}
}

@media screen  and (max-width:640px) {
	.subpage .section .growing .top .active span{
		display: none;
	}
	.subpage .about{
		width: 100%;
		padding:1rem 0;
	}
	.subpage .about .about-title{
		padding:0rem 0 0.5rem 0;
	}
	.subpage .section .company .left{
		width: 100%;
	}
	.subpage .section .company .right{
		width: 100%;
	}
	.subpage .section .company .left p{
		margin: 0;
		text-indent: 2em;
		line-height: 0.6rem;
	}	
	.subpage .about-second-section{
		padding: 0.6rem 0;
	}
	.subpage .about-second-section .enterprise ul li{
		width: 80%;
		min-height: 3.8rem;
		margin: 0.4rem auto;
	}
	.subpage .section .growing .alternate{
		padding:0 8%;
	}
	.subpage .section .growing .top{	
		margin-bottom: 0.6rem;
	}
	.subpage .section .growing .top li{  
		width: 100%;
		line-height: 0.6rem;
	}
	.subpage .section .growing .top li:last-child,
	.subpage .section .growing .top li:first-child{  
		display: none;
	}
	.subpage .section .join ul li{
		width: 100%;
		margin: 0;
		padding: 0;
		color: #666a75;
		line-height: 1rem;
		text-align: left;
	}
	.subpage .section .join ul .about-ul-first{
		height: 1rem;
		color: #fff;
		line-height: 1rem;
		font-size: 0.3rem;
		border-bottom: none;
		background-size: 100% 100%;
		background: url(../../img/join_bj.png) no-repeat center center;
	}
	.subpage .interlace-section .contact-us .left, 
	.subpage .interlace-section .contact-us .right{
		min-height: 0rem;
		padding: 0.3rem;
		width: 100%;
		text-align: left;
	}
	.subpage .interlace-section .contact-us .right{
		display: none;
	}
	.subpage .interlace-section .contact-us .left p{
		font-size: 0.26rem;
		margin: 0rem;
		line-height: 0.5rem;
	}
	.subpage .about-second-section .enterprise ul li span{
		line-height: 0.4rem;
	}
	.subpage .interlace-section .contact-us .left h5{
		margin-bottom: 0.3rem;
		font-size: 0.3rem;
		font-weight: bold;
		color: #000;
	}
	.subpage .interlace-section .contact-us .component-map{
		height: 2.6rem;
	}
	.subpage .interlace-section .certificate .carousel div{ 
		display: inline-block;
		vertical-align: top;
		width: 30%;
		margin: 2% 3%;
	}
}
@media screen  and (min-width: 1280px) and (max-width:1600px) {
	
	.subpage .section .company .left,
	.subpage .section .company .right,
	.subpage .section .company .right img{
		height: 6.6rem;
		margin: 0;
	}
	.subpage .section .company .right img{
		width: 100%;
	}
	.subpage .section .company .left{width: 64%;}
	.subpage .section .company .left p{
		margin: 0;
		text-indent: 2em;
		line-height: 0.6rem;
		font-size: 0.4rem;
	}
	.subpage .section .growing .alternate .ant-timeline-item-content{
		font-size: 0.4rem;
	}
	.subpage .section .company .right{width: 36%;}
	.subpage .about{
		width: 90%;
		padding:1rem 0;
	}
	.subpage .about .about-title{margin-bottom: 1rem;}	
	.subpage .about-second-section .enterprise ul li span{
		font-size: 0.4rem;
		line-height: 0.5rem;
	}
	.subpage .about-second-section .enterprise ul li{
		width: 26%;
		height: 5.7rem;
		display: inline-block;
		vertical-align: top;
		margin-right: 6%;
	}
	.subpage .about-second-section .enterprise ul li:last-child{
		margin-right: 0;
	}
	.subpage .section .growing .top{	
		margin-bottom: 1.6rem;
	}
	.subpage .section .growing .alternate{
		width:80%;
		margin: 0 auto;
	}
	.subpage .interlace-section .certificate .carousel div{ 
		display: inline-block;
		vertical-align: top;
		width: 15%;
		margin-right: 2% ;
	}
	.subpage .section .join ul li{
		line-height: 2rem;
	}
	.subpage .interlace-section .contact-us .component-map{
		height: 6.6rem;
	}
	.subpage .interlace-section .contact-us .left,
	.subpage .interlace-section .contact-us .right{
		min-height: 5rem;
		font-size: 0.4rem;
	}
	.subpage .interlace-section .contact-us .left p{
		margin-bottom: 0;
	}
	.subpage .section .join ul li{
		font-size: 0.4rem;
	}
}
@media screen  and (min-width: 1600px){
	.subpage .about{width: 62%;padding: 1rem 0 ;}
	.subpage .about .about-title{margin-bottom: 1rem;}
	.subpage .section .company .left,
	.subpage .section .company .right,
	.subpage .section .company .right img{
		height: 6.6rem;
		margin: 0;
	}
	.subpage .section .company .right img{
		width: 100%;
	}
	.subpage .section .company .left{width: 64%;}
	.subpage .section .company .left p{
		margin: 0;
		text-indent: 2em;
		line-height: 0.6rem;
		font-size: 0.37rem;
	}
	.subpage .section .company .right{width: 36%;}
	.subpage .about-second-section .enterprise ul li{
		width: 26%;
		height: 5.6rem;
		display: inline-block;
		vertical-align: top;
		margin-right: 6%;
	}
	.subpage .about-second-section .enterprise ul li span{
		font-size: 0.4rem;
		line-height: 0.5rem;
	}
	.subpage .about-second-section .enterprise ul li:last-child{
		margin-right: 0;
	}
	.subpage .section .growing .top{	
		margin-bottom: 1.6rem;
	}
	.subpage .section .growing .alternate{
		width:70%;
		margin: 0 auto;
	}
	/* .subpage .section .growing .alternate .ant-timeline-item-content{
		font-size: 0.4rem;
	} */
	.ant-timeline.ant-timeline-alternate .ant-timeline-item-right .ant-timeline-item-content{
		margin: 0rem -0.4rem;
	}
	.ant-timeline-item{
		height: 1.4rem !important;
	}
	.subpage .section .growing .alternate .ant-timeline-item-content{
		font-size: 0.4rem;
	}
	.subpage .section .growing .alternate .ant-timeline-item-content strong{
		font-size: 0.5rem;
		font-weight: 400;
		margin-right:0.4rem;
	}
	.subpage .section .growing .alternate .ant-timeline-item-content .strong-right{
		float: left;
	}
	.subpage .interlace-section .certificate .carousel div{ 
		display: inline-block;
		vertical-align: top;
		width: 15.5%;
		margin-right: 4.5% ;
	}
	.subpage .section .join ul li{
		line-height: 2rem;
	}
	/* .subpage .interlace-section .contact-us .component-map{
		height: 6.6rem;
	} */
	.subpage .interlace-section .contact-us .left,
	.subpage .interlace-section .contact-us .right{
		min-height: 5rem;
		font-size: 0.4rem;
	}
	.subpage .interlace-section .contact-us .left p{
		margin-bottom: 0;
	}
	.subpage .section .join ul li{
		font-size: 0.4rem;
	}
	.subpage .interlace-section .contact-us .left h5{
		line-height: 1rem;
	}
	.subpage .interlace-section .contact-us .left p,
	.subpage .section .growing .top li{
		line-height: 0.6rem;
	}
	.subpage .about .ant-carousel .slick-dots li button{
		width: 20px !important;
		height: 20px !important;
		border-radius: 50% !important;
	}
	.subpage .about .ant-carousel .carousel{
		height: 6.8rem;
	}
}
.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding-top: 12px;
    padding-bottom: 12px;
    /* background: #e3ecf4; */
    border-top: 1px solid #d9d9d9 !important;
}
.ant-collapse-content > .ant-collapse-content-box p {
    text-align: left;
    padding-left: 1rem;
	font-size: 0.4rem;
	color: #85868a;
}
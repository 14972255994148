.subpage .hardware2300-header {
  background-image: url(../../img/banner2.jpg);
  background-size: 100% 100%;
}
.subpage .hardware2300-section {
  width: 100%;
  background: url(../../img/yj_bj.jpg) bottom no-repeat;
}
.subpage .hardware2300-second,
.hardware2300-third {
  margin: 0 auto;
}
.subpage .hardware2300-second .hardware2300-second-left,
.subpage .hardware2300-second .hardware1900-second-left,
.subpage .hardware2300-second .hardware3500-second-left,
.subpage .hardware2300-second .hardware2300-second-right {
  display: inline-block;
  vertical-align: top;
}
.subpage .hardware2300-second .hardware1900-second-left {
  background: url(../../img/data1900.png) center center no-repeat;
  background-size: contain;
  background-color: rgb(227, 236, 244);
}
.subpage .hardware2300-second .hardware2300-second-left {
  background: url(../../img/data2700.png) center center no-repeat;
  background-size: contain;
  background-color: rgb(227, 236, 244);
}
.subpage .hardware2300-second .hardware3500-second-left {
  background: url(../../img/data3500.png) center center no-repeat;
  background-size: contain;
  background-color: rgb(227, 236, 244);
}
.subpage .hardware2300-second .hardware2300-second-right h2 {
  color: rgb(35, 24, 21);
  font-weight: bold;
  text-align: left;
  padding: 0;
  margin: 0;
}
.subpage .hardware2300-second .hardware2300-second-right h5 {
  font-family: 'Source Han Sans CN';
  color: rgb(35, 24, 21);
  text-align: left;
  border-bottom: solid 1px rgb(207, 207, 207);
}
.subpage .hardware2300-second .hardware2300-second-right p {
  font-family: 'Microsoft YaHei';
  color: rgb(0, 0, 0);
  margin: 0;
  padding: 0;
}
.subpage .hardware2300-third ul,
.subpage .hardware2300-third ul li {
  padding: 0;
  margin: 0;
  list-style: none;
}
.subpage .hardware2300-third ul li {
  margin: 0;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 7px 0px rgba(4, 0, 0, 0.18);
}
.subpage .hardware2300-third ul li img,
.subpage .hardware2300-third ul li div {
  display: inline-block;
  vertical-align: middle;
}
.subpage .hardware2300-third ul li div h5,
.subpage .hardware2300-third ul li div p {
  color: rgb(0, 0, 0);
}
.subpage .hardware2300-four {
  background: rgb(30, 101, 193);
}
.subpage .hardware2300-four h1 {
  text-align: center;
  color: #fff;
}
.subpage .hardware2300-four ul {
  margin: 0 auto;
}
.subpage .hardware2300-four ul li {
  list-style: none;
  border-bottom: solid 1px rgb(255, 255, 255, 0.1);
  color: #fff;
}
@media screen and (max-width: 640px) {
  .subpage .hardware2300-section {
    padding: 0;
  }
  .subpage .hardware2300-second,
  .hardware2300-third {
    width: 100%;
  }
  .subpage .hardware2300-second .hardware2300-second-left,
  .subpage .hardware2300-second .hardware1900-second-left,
  .subpage .hardware2300-second .hardware3500-second-left,
  .subpage .hardware2300-second .hardware2300-second-right {
    display: inline-block;
    vertical-align: top;
  }
  .subpage .hardware2300-second .hardware2300-second-left,
  .subpage .hardware2300-second .hardware1900-second-left,
  .subpage .hardware2300-second .hardware3500-second-left {
    width: 100%;
    height: 100%;
    min-height: 4rem;
  }
  .subpage .hardware2300-second .hardware2300-second-right {
    width: 100%;
    padding: 0.3rem;
  }
  .subpage .hardware2300-second .hardware2300-second-right h2 {
    font-size: 0.4rem;
    line-height: 0.6rem;
    text-align: center;
  }
  .subpage .hardware2300-second .hardware2300-second-right h5 {
    font-size: 0.32rem;
    line-height: 0.6rem;
    text-align: center;
    border-bottom: solid 1px rgb(207, 207, 207);
  }
  .subpage .hardware2300-second .hardware2300-second-right p {
    font-size: 0.26rem;
    line-height: 0.5rem;
    text-indent: 0.6rem;
  }
  .subpage .hardware2300-third ul {
    margin-top: 0.3rem;
  }
  .subpage .hardware2300-third ul li {
    display: block;
    width: 100%;
    margin-bottom: 0.4rem;
    padding-left: 5%;
  }
  .subpage .hardware2300-third ul li img,
  .subpage .hardware2300-third ul li div {
    display: inline-block;
    vertical-align: middle;
  }
  .subpage .hardware2300-third ul li img {
    width: 15%;
  }
  .subpage .hardware2300-third ul li div {
    width: 80%;
    padding: 0 0.5rem;
    padding-top: 6%;
  }
  .subpage .hardware2300-third ul li div h5,
  .subpage .hardware2300-third ul li div p {
    line-height: 0.46rem;
  }
  .subpage .hardware2300-third ul li div h5 {
    font-size: 0.32rem;
  }
  .subpage .hardware2300-third ul li div p {
    font-size: 0.26rem;
  }
  .subpage .hardware2300-four {
    width: 100%;
    padding: 0.6rem 0;
  }
  .subpage .hardware2300-four h1 {
    width: 100%;
    text-align: center;
    font-size: 0.36rem;
    line-height: 0.4rem;
  }
  .subpage .hardware2300-four ul {
    width: 100%;
    padding: 0;
  }
  .subpage .hardware2300-four ul li {
    line-height: 0.8rem;
    font-size: 0.26rem;
    padding-left: 4%;
  }
  .subpage .hardware2300-four ul li strong {
    margin-right: 4%;
    width: 1.2rem;
    height: 100%;
    display: inline-block;
  }
}
@media screen and (min-width: 640px) and (max-width: 1270px) {
  .subpage .hardware2300-section {
    padding: 0;
  }
  .subpage .hardware2300-second,
  .hardware2300-third {
    width: 100%;
  }
  .subpage .hardware2300-second .hardware2300-second-left,
  .subpage .hardware2300-second .hardware1900-second-left,
  .subpage .hardware2300-second .hardware3500-second-left,
  .subpage .hardware2300-second .hardware2300-second-right {
    display: inline-block;
    vertical-align: top;
  }
  .subpage .hardware2300-second .hardware2300-second-left,
  .subpage .hardware2300-second .hardware1900-second-left,
  .subpage .hardware2300-second .hardware3500-second-left {
    width: 40%;
    height: 100%;
    min-height: 9.8rem;
  }
  .subpage .hardware2300-second .hardware2300-second-right {
    width: 60%;
    padding: 0.3rem;
    text-align: left;
  }
  .subpage .hardware2300-second .hardware2300-second-right h2 {
    font-size: 0.5rem;
    line-height: 0.8rem;
  }
  .subpage .hardware2300-second .hardware2300-second-right h5 {
    font-size: 0.45rem;
    line-height: 0.8rem;
    border-bottom: solid 1px rgb(207, 207, 207);
  }
  .subpage .hardware2300-second .hardware2300-second-right p {
    font-size: 0.4rem;
    line-height: 0.7rem;
    text-indent: 0.6rem;
  }
  .subpage .hardware2300-third ul {
    margin-top: 0.3rem;
  }
  .subpage .hardware2300-third ul li {
    display: block;
    width: 100%;
    padding-left: 2%;
    margin-bottom: 0.4rem;
    padding: 1rem;
  }
  .subpage .hardware2300-third ul li img,
  .subpage .hardware2300-third ul li div {
    display: inline-block;
    vertical-align: middle;
  }
  .subpage .hardware2300-third ul li img {
    width: 15%;
  }
  .subpage .hardware2300-third ul li div {
    width: 80%;
    padding: 0 0.5rem;
  }
  .subpage .hardware2300-third ul li div h5,
  .subpage .hardware2300-third ul li div p {
    line-height: 0.6rem;
  }
  .subpage .hardware2300-third ul li div h5 {
    font-size: 0.5rem;
  }
  .subpage .hardware2300-third ul li div p {
    font-size: 0.4rem;
  }
  .subpage .hardware2300-four {
    width: 100%;
    padding: 0.6rem 0;
  }
  .subpage .hardware2300-four h1 {
    width: 100%;
    text-align: center;
    font-size: 0.6rem;
    line-height: 1rem;
  }
  .subpage .hardware2300-four ul {
    width: 100%;
    padding: 0;
  }
  .subpage .hardware2300-four ul li {
    line-height: 1.4rem;
    font-size: 0.4rem;
    padding-left: 4%;
  }
  .subpage .hardware2300-four ul li strong {
    margin-right: 4%;
    width: 3rem;
    display: inline-block;
  }
}
@media screen and (min-width: 1270px) and (max-width: 1600px) {
  .subpage .hardware2300-section {
    padding: 1rem 0;
  }
  .subpage .hardware2300-second,
  .hardware2300-third {
    width: 80%;
  }
  .subpage .hardware2300-second .hardware2300-second-left,
  .subpage .hardware2300-second .hardware1900-second-left,
  .subpage .hardware2300-second .hardware3500-second-left,
  .subpage .hardware2300-second .hardware2300-second-right {
    display: inline-block;
    vertical-align: top;
  }
  .subpage .hardware2300-second .hardware2300-second-left,
  .subpage .hardware2300-second .hardware1900-second-left,
  .subpage .hardware2300-second .hardware3500-second-left {
    width: 30%;
    height: 100%;
    min-height: 8rem;
  }
  .subpage .hardware2300-second .hardware2300-second-right {
    width: 65%;
    margin-left: 2.5%;
  }
  .subpage .hardware2300-second .hardware2300-second-right h2 {
    font-size: 0.7rem;
    line-height: 1.2rem;
    text-align: left;
  }
  .subpage .hardware2300-second .hardware2300-second-right h5 {
    font-size: 0.5rem;
    line-height: 1rem;
    text-align: left;
    border-bottom: solid 1px rgb(207, 207, 207);
  }
  .subpage .hardware2300-second .hardware2300-second-right p {
    font-size: 0.4rem;
    line-height: 1.857;
    text-indent: 1rem;
  }
  .subpage .hardware2300-third ul {
    margin-top: 1rem;
  }
  .subpage .hardware2300-third ul li {
    display: inline-block;
    vertical-align: top;
    width: 30%;
    margin: 0;
    margin-right: 3%;
    padding-left: 1%;
    min-height: 4.8rem;
  }
  .subpage .hardware2300-third ul li img,
  .subpage .hardware2300-third ul li div {
    display: inline-block;
    vertical-align: middle;
  }
  .subpage .hardware2300-third ul li img {
    width: 15%;
  }
  .subpage .hardware2300-third ul li div {
    width: 80%;
    padding: 0 0.5rem;
    padding-top: 6%;
  }
  .subpage .hardware2300-third ul li div h5,
  .subpage .hardware2300-third ul li div p {
    line-height: 0.7rem;
  }
  .subpage .hardware2300-third ul li div h5 {
    font-size: 0.5rem;
  }
  .subpage .hardware2300-third ul li div p {
    font-size: 0.4rem;
  }
  .subpage .hardware2300-four {
    width: 100%;
    padding: 1rem 0;
  }
  .subpage .hardware2300-four h1 {
    width: 100%;
    text-align: center;
    font-size: 0.6rem;
    line-height: 1.4rem;
  }
  .subpage .hardware2300-four ul {
    width: 20rem;
  }
  .subpage .hardware2300-four ul li {
    line-height: 1.4rem;
    font-size: 0.4rem;
  }
  .subpage .hardware2300-four ul li strong {
    margin-right: 1rem;
    width: 3rem;
    display: inline-block;
  }
}
@media screen and (min-width: 1600px) {
  .subpage .hardware2300-section {
    padding: 1rem 0;
  }
  .subpage .hardware2300-second,
  .hardware2300-third {
    width: 62%;
  }
  .subpage .hardware2300-second .hardware2300-second-left,
  .subpage .hardware2300-second .hardware1900-second-left,
  .subpage .hardware2300-second .hardware3500-second-left,
  .subpage .hardware2300-second .hardware2300-second-right {
    display: inline-block;
    vertical-align: top;
  }
  .subpage .hardware2300-second .hardware2300-second-left,
  .subpage .hardware2300-second .hardware1900-second-left,
  .subpage .hardware2300-second .hardware3500-second-left {
    width: 30%;
    height: 100%;
    min-height: 8rem;
  }
  .subpage .hardware2300-second .hardware2300-second-right {
    width: 65%;
    margin-left: 2.5%;
  }
  .subpage .hardware2300-second .hardware2300-second-right h2 {
    font-size: 0.7rem;
    line-height: 1.2rem;
    text-align: left;
  }
  .subpage .hardware2300-second .hardware2300-second-right h5 {
    font-size: 0.5rem;
    line-height: 1rem;
    text-align: left;
    border-bottom: solid 1px rgb(207, 207, 207);
  }
  .subpage .hardware2300-second .hardware2300-second-right p {
    font-size: 0.4rem;
    line-height: 1.857;
    text-indent: 1rem;
  }
  .subpage .hardware2300-third ul {
    margin-top: 1rem;
  }
  .subpage .hardware2300-third ul li {
    display: inline-block;
    vertical-align: top;
    width: 30%;
    margin: 0;
    margin-right: 3%;
    padding-left: 1%;
    min-height: 4rem;
  }
  .subpage .hardware2300-third ul li img,
  .subpage .hardware2300-third ul li div {
    display: inline-block;
    vertical-align: middle;
  }
  .subpage .hardware2300-third ul li img {
    width: 15%;
  }
  .subpage .hardware2300-third ul li div {
    width: 80%;
    padding: 0 0.5rem;
    padding-top: 6%;
  }
  .subpage .hardware2300-third ul li div h5,
  .subpage .hardware2300-third ul li div p {
    line-height: 0.7rem;
  }
  .subpage .hardware2300-third ul li div h5 {
    font-size: 0.5rem;
  }
  .subpage .hardware2300-third ul li div p {
    font-size: 0.4rem;
  }
  .subpage .hardware2300-four {
    width: 100%;
    padding: 1rem 0;
  }
  .subpage .hardware2300-four h1 {
    width: 100%;
    text-align: center;
    font-size: 0.6rem;
    line-height: 1.4rem;
  }
  .subpage .hardware2300-four ul {
    width: 20rem;
  }
  .subpage .hardware2300-four ul li {
    line-height: 1.4rem;
    font-size: 0.4rem;
  }
  .subpage .hardware2300-four ul li strong {
    margin-right: 1rem;
    width: 3rem;
    display: inline-block;
  }
}

.subpage .agent-header{    
	width: 100%;
	color: #fff;
	background: url(../../img/zs_banner.jpg) no-repeat center center;
	background-size: cover;
}
.subpage .agent-title{
	font-weight: bold;
}
.subpage .agent-section{	
	margin: 0 auto;
	text-align: center;
}
.subpage .section .agent-section .first-top{	
	width: 100%;
	background: url(../../img/zs_bj1.jpg) no-repeat center center;
	background-size: cover;
	color: #fff;
}
.subpage .section .agent-section .first-top h3{	
	font-weight: bold;
	color: #fff;
}
.subpage .section .agent-section .first-top div{
	display: inline-block;
	background: rgba(255,255,255,0.4);
}
.subpage .section .agent-section .first-top div h1{	
	color: #fff;
	font-weight: normal;
	padding: 0;
	margin: 0;
}
.subpage .section .agent-section .first-top div p{	
	font-weight: normal;
	color: #fff;
	margin: 0;
}
.subpage .section .agent-section .first-ul,
.subpage .section .agent-section .first-ul li{	
	list-style: none;
	padding: 0;
	margin: 0;
	text-align: left;
}
.subpage .section .agent-section .first-ul li{
	text-align: center;
	display: inline-block;
	vertical-align: top;
	border-right: solid 1px #c1c1c1;
}
.subpage .section .agent-section .first-ul li:last-child{	
	border-right: none;
}
.subpage .section .agent-section .first-ul li div{	
	display: inline-block;
	vertical-align: middle;
}
.subpage .section .agent-section .first-ul li div img{	
	min-width:90px;
	display: inline-block;
	vertical-align: middle;
}
.subpage .section .agent-section .first-ul li span{	
	display: inline-block;
	vertical-align: middle;
	text-align: left;
	color: #65656a;
}
.second-section ul,
.second-section ul li{
	list-style: none;
	padding: 0;
	margin: 0;
	text-align: center;
}
.second-section ul li{
	background: #FFFFFF;
	display: inline-block;
	vertical-align: top;
	text-align: left;
}
.second-section ul li:nth-child(2),
.second-section ul li:nth-child(4){
	margin-right: 0%;
}
.second-section ul li span{
	text-align: left;
	font-weight: bold;
}
.subpage .section  .third-section ul li{
	display: inline-block;
	vertical-align: top;
}
.subpage .section  .third-section ul li .target-second,
.subpage .section  .third-section ul li .target-one{
	background: #e3ecf4;
	margin: 0 auto;
	text-align: center;
}
.subpage .section  .third-section ul li .target-second p{
	width: 0;
	height: 0;
	display: block;
	margin: 0 auto;
	text-align: center;
}
.subpage .section  .third-section ul li .target-one p{
	width: 0;
	height: 0;
	display: block;
	margin: 0 auto;
	text-align: center;
}
.subpage .customer-section{
	color: #fff;
	background: url(../../img/zs_bj2.jpg) no-repeat center center;
	background-size: cover;
}
.subpage .customer-section .agent-section  .customer-title{
	font-weight: bold;
	color: #fff;
}
.subpage .customer-section .agent-section p img{
	margin: 0  auto;
}
@media screen  and (max-width:640px){
	.subpage .agent-header{    
		min-height: 5rem;
	}
	.subpage .section .agent-section .first-top{
		min-height: 3rem;
	}
	.subpage .section .agent-section .first-top h3{	
		font-size: 0.3rem;
		padding-top: 0.6rem;
		margin-bottom:0.6rem ;
	}
	.subpage .section .agent-section .first-top div{
		min-width: 40%;
		padding: 0.4rem ;
		margin-bottom: 0.6rem;
	}
	.subpage .section .agent-section .first-top div h1{	
		font-size: 0.4rem;
		line-height: 0.8rem;
	}
	.subpage .section .agent-section .first-top div p{	
		font-size: 0.32rem;
	}
	.subpage .section .agent-section .first-ul li {
		text-align: center;
		display: block;
		border-right: none;
		width: 80%;
		margin: 0 auto;
		margin-top: 0.2rem;
	}
	.subpage .agent-section{
		padding: 0.4rem 0;
	}
	.second-section ul li{
		width: 100%;
		margin-top: 0.4rem;
		padding: 0.2rem 0.2rem;
	}
	.second-section ul li img{
		margin-right: 0.2rem;
	}
	.subpage .section .third-section ul{
		padding: 0;
		margin: 0;
	}
	.subpage .section .third-section ul li{
		display: block;
		width: 80%;
		margin: 0 auto;
		margin-bottom: 0.4rem;
		padding: 0;
	}
	.subpage .section .third-section ul li img{
		width:100%;
	}
	.subpage .section .third-section ul{
		margin-top: 0.4rem;
	}
	.subpage .section  .third-section ul li .target-second,
	.subpage .section  .third-section ul li .target-one{
		font-size: 0.26rem;
		line-height: 0.8rem;
	}
	.customer-section .agent-section .customer-contact{
		padding-top: 0.4rem;
	}
	.customer-section .agent-section .customer-contact img{
		width: 40%;
		margin: 3%;
	}
	.subpage .customer-section{
		padding-top: 0.4rem;
	}
	.subpage .customer-section .agent-section p img{
		width: 100%;
	}
}
@media screen and (max-width: 1280px) and (min-width: 640px){
	.subpage .agent-header{    
		min-height: 765px;
	}
	.subpage .agent-title{
		font-size: 0.6rem;
		margin-bottom: 0.8rem;
	}
	.subpage .section {	
		width: 100%;
		margin: 0rem auto;
	}
	.subpage .section .third-section ul,
	.subpage .section .third-section ul li{
		margin: 0;
		padding: 0;
	}
	.subpage .section .agent-section .first-top h3{	
		font-size: 0.6rem;
		padding-top: 1rem;
		margin-bottom:0.8rem ;
	}
	.subpage .section .agent-section .first-top div{
		min-width: 40%;
		padding: 0.6rem ;
		margin-bottom: 1.2rem;
	}
	.subpage .section .agent-section .first-top div h1{	
		font-size: 0.8rem;
		line-height: 1.6rem;
	}
	.subpage .section .agent-section .first-top div p{	
		font-size: 0.4rem;
		line-height: 1rem;
	}
	.subpage .section .agent-section .first-ul li{	
		width: 33%;
		padding: 0.4rem 0;
		margin-top: 1rem;
		margin-bottom: 0.4rem;
	}
	.subpage .section .agent-section .first-ul li div{	
		width: 38%;
	}
	.subpage .section .agent-section .first-ul li div img{	
		min-width:90px;
	}
	.subpage .section .agent-section .first-ul li span{	
		width: 58%;
		font-size: 0.4rem;
		line-height: 0.6rem;
	}
	.second-section ul li{
		width: 100%;
		padding:3% 10%;
		margin-bottom: 0.4rem;
		border-radius: 10px;
		box-shadow: 1px 1px 5px #bfbfbf;
	}
	.second-section ul li span{
		width: 70%;
		font-size: 0.45rem;
		margin-left: 1rem;
	}
	.subpage .section  .third-section ul li{
		width: 20%;
	}
	.subpage .section  .third-section ul li .target-second,
	.subpage .section  .third-section ul li .target-one{
		font-size: 0.45rem;
		line-height: 1rem;
		height: 2rem;
	}
	.subpage .section  .third-section ul li .target-second{padding-top: 0.4rem;}
	.subpage .section  .third-section ul li .target-one{padding-top: 0.6rem;}
	.subpage .section  .third-section ul li .target-second span,
	.subpage .section  .third-section ul li .target-one span{
		line-height: 1rem;
	}
	.subpage .section  .third-section ul li .target-second p{
		border: 0.3rem solid;
		border-color: transparent transparent #003567;
	}
	.subpage .section  .third-section ul li .target-one p{
		border-left: 0.3rem solid transparent;
		border-right: 0.3rem solid transparent;
		border-top: 0.3rem solid #003567;
	}
	.subpage .section  .third-section ul li .target-img{
		width: 100%;
	}
	.subpage .section  .third-section ul li .target-img img{
		width: 100%;
	}
	.subpage .customer-section{
		width: 100%;
		min-height: 10rem;
		padding: 1rem 0;
	}
	.subpage .customer-section .agent-section  .customer-title{
		font-size: 0.6rem;
		margin-bottom: 1rem;
	}
	.subpage .customer-section .agent-section .customer-contact{
		width: 100%;
	}
	.subpage .customer-section .agent-section .customer-contact img{
		width: 18%;
		margin: 1%;
	}
	.subpage .customer-section .agent-section p img{
		margin-top: 1.6rem;
	}
}
@media screen and (max-width: 1600px) and (min-width: 1280px){
	.subpage .agent-header{    
		min-height: 765px;
	}
	.subpage .agent-title{
		font-size: 0.6rem;
		margin-bottom: 0.8rem;
	}
	.subpage .agent-section{	
		width: 80%;
		margin: 1rem auto;
	}
	.subpage .section .agent-section .first-top h3{	
		font-size: 0.6rem;
		padding-top: 1rem;
		margin-bottom:0.8rem ;
	}
	.subpage .section .agent-section .first-top div{
		min-width: 40%;
		padding: 0.6rem ;
		margin-bottom: 1.2rem;
	}
	.subpage .section .agent-section .first-top div h1{	
		font-size: 0.8rem;
	}
	.subpage .section .agent-section .first-top div p{	
		font-size: 0.4rem;
	}
	.subpage .section .agent-section .first-ul li{	
		width: 33%;
		padding: 0.4rem 0;
		margin-top: 1rem;
		margin-bottom: 0.4rem;
	}
	.subpage .section .agent-section .first-ul li div{	
		width: 42%;
	}
	.subpage .section .agent-section .first-ul li div img{	
		min-width:90px;
	}
	.subpage .section .agent-section .first-ul li span{	
		width: 54%;
		font-size: 0.4rem;
	}.second-section ul li{
		width: 46%;
		margin-right: 4%;
		padding:2% 2%;
		margin-bottom: 30px;
		border-radius: 10px;
		box-shadow: 1px 1px 5px #bfbfbf;
	}
	.second-section ul li span{
		width: 70%;
		font-size: 0.45rem;
		margin-left: 1rem;
	}
	.subpage .section  .third-section ul li{
		width: 20%;
	}
	.subpage .section  .third-section ul li .target-second,
	.subpage .section  .third-section ul li .target-one{
		font-size: 0.45rem;
		line-height: 1rem;
		height: 2.8rem;
	}
	.subpage .section  .third-section ul li .target-second{padding-top: 0.7rem;}
	.subpage .section  .third-section ul li .target-one{padding-top: 1rem;}
	.subpage .section  .third-section ul li .target-second span,
	.subpage .section  .third-section ul li .target-one span{
		line-height: 1rem;
	}
	.subpage .section  .third-section ul li .target-second p{
		border: 0.3rem solid;
		border-color: transparent transparent #003567;
	}
	.subpage .section  .third-section ul li .target-one p{
		border-left: 0.3rem solid transparent;
		border-right: 0.3rem solid transparent;
		border-top: 0.3rem solid #003567;
	}
	.subpage .section  .third-section ul li .target-img{
		width: 100%;
		min-height: 112px;
	}
	.subpage .section  .third-section ul li .target-img img{
		width: 100%;
	}
	.subpage .customer-section{
		width: 100%;
		min-height: 10rem;
		padding: 1rem 0;
	}
	.subpage .customer-section .agent-section  .customer-title{
		font-size: 0.6rem;
		margin-bottom: 1rem;
	}
	.subpage .customer-section .agent-section  .customer-img{
		margin-right: 2%;
		width: 18%;
	}
	.subpage .customer-section .agent-section p img{
		margin-top: 1.6rem;
	}
}
@media screen  and (min-width: 1600px){
	.subpage .agent-header{    
		min-height: 765px;
	}
	.subpage .agent-title{
		font-size: 0.6rem;
		margin-bottom: 0.8rem;
	}
	.subpage .agent-section{	
		width: 64%;
	}
	.subpage .section .agent-section .first-top h3{	
		font-size: 0.6rem;
		padding-top: 1rem;
		margin-bottom:0.8rem ;
	}
	.subpage .section .agent-section .first-top div{
		min-width: 40%;
		padding: 0.6rem ;
		margin-bottom: 1.2rem;
	}
	.subpage .section .agent-section .first-top div h1{	
		font-size: 0.8rem;
		line-height: 1rem;
	}
	.subpage .section .agent-section .first-top div p{	
		font-size: 0.4rem;
		line-height: 0.8rem;
	}
	.subpage .section .agent-section .first-ul li{	
		width: 33%;
		padding: 0.4rem 0;
		margin-top: 1rem;
		margin-bottom: 0.4rem;
	}
	.subpage .section .agent-section .first-ul li div{	
		width: 42%;
	}
	.subpage .section .agent-section .first-ul li div img{	
		min-width:90px;
	}
	.subpage .section .agent-section .first-ul li span{	
		width: 58%;
		font-size: 0.4rem;
		line-height: 0.6rem;
	}
	.second-section ul li{
		width: 46%;
		margin-right: 4%;
		padding:2% 2%;
		margin-bottom: 30px;
		border-radius: 10px;
		box-shadow: 1px 1px 5px #bfbfbf;
	}
	.second-section ul li span{
		width: 70%;
		font-size: 0.45rem;
		margin-left: 1rem;
	}
	.subpage .section  .third-section ul li{
		width: 20%;
	}
	.subpage .section  .third-section ul li .target-second,
	.subpage .section  .third-section ul li .target-one{
		font-size: 0.45rem;
		line-height: 1rem;
		height: 3rem;
	}
	.subpage .section  .third-section ul li .target-second{padding-top: 0.7rem;}
	.subpage .section  .third-section ul li .target-one{padding-top: 1rem;}
	.subpage .section  .third-section ul li .target-second span,
	.subpage .section  .third-section ul li .target-one span{
		line-height: 1rem;
	}
	.subpage .section  .third-section ul li .target-second p{
		border: 0.3rem solid;
		border-color: transparent transparent #003567;
	}
	.subpage .section  .third-section ul li .target-one p{
		border-left: 0.3rem solid transparent;
		border-right: 0.3rem solid transparent;
		border-top: 0.3rem solid #003567;
	}
	.subpage .section  .third-section ul li .target-img{
		width: 100%;
		min-height: 112px;
	}
	.subpage .section  .third-section ul li .target-img img{
		width: 100%;
	}
	.subpage .customer-section{
		width: 100%;
		min-height: 10rem;
		padding: 1rem 0;
	}
	.subpage .customer-section .agent-section  .customer-title{
		font-size: 0.6rem;
		margin-bottom: 1rem;
	}
	.subpage .customer-section .agent-section  .customer-img{
		margin-right: 2%;
		width: 18%;
	}
	.subpage .customer-section .agent-section p img{
		margin-top: 1.6rem;
	}	
}
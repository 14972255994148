.header_right .nav{
	display: inline-block;
	height: 100%;
	font-size: 0.4rem;
}
.header_right .nav ul,
.header_right .nav .nav-ul span{
	list-style: none;
	padding: 0;
	margin: 0;
}
.header_right .nav .nav-ul span{
	transition: all .3s;
}
.show{
	display: block;
}
.disappear{
	display: none;
}
@media screen  and (max-width:640px) {
	.header_left,.header_outline,.header_english{
		display: inline-block;
		vertical-align: top;
		height: 1.4rem;
		text-align: center;
		color:#ffffff;
	}
	.header_left{
		width: 50%;
		padding-top: 5%;
		border-right: 1px solid rgba(255, 255, 255, 0.2);
	}
	.header_left img{
		min-width: 2rem;
	}
	.header_outline{
		width:50%;
		padding-top: 6%;
		text-align: right;
	}
	.outline{
		width:0.6rem;
		height: 0.7rem;
		line-height: 0.8rem;
		cursor: pointer;
		font-size:0.42rem;
		margin-right: 0.4rem;
		border-radius: 0.1rem;
		font-family: "arial black";
		background-color: #0079e8;
		border: solid 1px #007be8;
	}
	.header_right{	
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		text-align: left;
		z-index: 999;
		background-color: #fff;
		border-bottom: rgba(0,0,0,.3);
		box-shadow: 0 3px 6px -4px rgba(0,0,0,.12),0 6px 16px 0 rgba(0,0,0,.08),0 9px 28px 8px rgba(0,0,0,.05);
	}
	.header_english{
		display: none;
	}
	.header_right .nav{
		width: 100%;
	}
	.header_right .nav .nav-ul span{
		display: block;
		text-align: left;
		clear: both;
		margin: 0;
		padding: 0 0.2rem;
		color: rgba(0,0,0,.85);
		font-weight: 400;
		font-size: 0.26rem;
		line-height: 0.6rem;
		white-space: nowrap;
		cursor: pointer;
	}
	.header_right .nav .nav-ul span a{
		color: rgba(0,0,0,.85);
		width: 100%;
		display: block;
	}
	.header_right .nav .nav-ul span span{
		display: inline-block;
		padding-left: 5%;
	}
	/* 产品中心二级产品 */
	.header_right .nav .nav-ul .subpage-software li h5{
		display: none;
	}
	/* 二级解决方案*/
	.header_right .nav .nav-ul li p{
		line-height: 0.2rem;
		margin: 0;
	}
}
@media screen and (min-width:640px)  and (max-width:1270px) {
	.header_left,.header_outline,.header_english{
		display: inline-block;
		vertical-align: top;
		height: 2.2rem;
		text-align: center;
		color:#ffffff;
	}
	.header_left{
		width: 20%;
		border-right: 1px solid rgba(255, 255, 255, 0.2);
	}
	.header_left img{
		min-width: 3rem;
		margin-top: 10%;
		
	}
	.header_right{	
		position: absolute;
		top: 2rem;
		right: 1rem;
		width: 3.6rem;
		text-align: left;
		outline: none;
		z-index: 999;
		background-color: #fff;
		border-radius: 2px;
		outline: none;
		-webkit-box-shadow: 0 3px 6px -4px rgba(0,0,0,.12),0 6px 16px 0 rgba(0,0,0,.08),0 9px 28px 8px rgba(0,0,0,.05);
		box-shadow: 0 3px 6px -4px rgba(0,0,0,.12),0 6px 16px 0 rgba(0,0,0,.08),0 9px 28px 8px rgba(0,0,0,.05);		
	}
	.header_outline{
		width:80%;
		text-align: right;
	}
	.outline{
		width:1rem;
		height: 1rem;
		cursor: pointer;
		font-size:0.6rem;
		margin-top: 0.6rem;
		margin-right: 1rem;
		line-height: 1.2rem;
		border-radius: 0.1rem;
		font-family: "arial black";
		background-color: #0079e8;
	}
	.header_english{
		display: none;
	}
	.header_right .nav{
		width: 100%;
	}
	.header_right .nav .nav-ul span{
		display: block;
		text-align: left;
		clear: both;
		margin: 0;
		padding: 0.12rem 0.4rem;
		color: rgba(0,0,0,.85);
		font-weight: 400;
		font-size: 0.36rem;
		line-height: 0.6rem;
		white-space: nowrap;
		cursor: pointer;
	}
	.header_right .nav .nav-ul span:hover{
		background: #f5f5f5;
		border-radius: 0.08rem;
	}
	.header_right .nav .nav-ul span a{
		color: rgba(0,0,0,.85);
	}
	.header_right .nav .nav-ul span span{
		display: inline-block;
		padding-left: 5%;
	}
	/* 二级导航 */
	.header_right .nav .nav-ul .subpage-nav{
		position: absolute;
		background: #f3f6f8;
		color: #000;
	}
	.header_right .nav .nav-ul .subpage-nav b{
		position: absolute;
		width: 0;
		height: 0;
		border-bottom: 0.2rem solid transparent;
		border-left: 0.2rem solid #fff;
		border-top: 0.2rem solid transparent;
		color: #fff;
		right: -0.2rem;
	}
	.header_right .nav .nav-ul .subpage-nav{
		background-color: #fff;
		border-radius: 2px;
		outline: none;
		-webkit-box-shadow: 0 3px 6px -4px rgba(0,0,0,.12),0 6px 16px 0 rgba(0,0,0,.08),0 9px 28px 8px rgba(0,0,0,.05);
		box-shadow: 0 3px 6px -4px rgba(0,0,0,.12),0 6px 16px 0 rgba(0,0,0,.08),0 9px 28px 8px rgba(0,0,0,.05);
		display: block;
		width: 7rem;
		right: 3.65rem;
		top: 1rem;
		padding: 0.3rem 0;
	}
	.header_right .nav .nav-ul .subpage-nav span{
		font-size: 0.2rem;
		display: inline-block;
		vertical-align: top;
		line-height: 0;
		width: 0.4rem;
		height: 0.4rem;
		border-radius: 50%;
		border: solid 1px #535353;
		margin-right: 0.4rem;
	}
	.header_right .nav .nav-ul .subpage-nav span svg{
		margin-left: 14%;
		font-size: 0.28rem;
		line-height: 0 !important;
		margin: -3px 0 0 3px;
	}
	.header_right .nav .nav-ul .subpage-nav li{
		display: block;
		width: 100%;
		background: #ffffff;
	}
	.header_right .nav .nav-ul .subpage-nav li p,
	.header_right .nav .nav-ul .subpage-nav li h5{
		display: block;
		font-size: 0.35rem;
		width: 100%;
		color: rgba(0,0,0,.85);
		text-align: left;
	}
	.header_right .nav .nav-ul .subpage-nav li h5{
		display: block;
		line-height: 0.6rem;
		width: 100%;
		color: #2e4446;
		font-size: 0.35rem;
	}
	.header_right .nav .nav-ul .subpage-nav li p{
		color:rgba(0,0,0,.65);
		line-height: 0.4rem;
	}
	.header_right .nav .nav-ul .subpage-nav li p span{
		padding-left: 0;
	}
	/* 产品中心二级产品 */
	.header_right .nav .nav-ul .subpage-software{
		display: none;
		top: 0rem;
	}
	.header_right .nav .nav-ul .subpage-software b{		
		top: 1rem;
	}
	.header_right .nav .nav-ul .subpage-software li p,
	.header_right .nav .nav-ul .subpage-software li h5{
		padding-left: 0.4rem;
	}
	/* 二级解决方案*/
	.header_right .nav .nav-ul .subpage-solution{
		top: 0;
		display: none;
	}
	.header_right .nav .nav-ul .subpage-solution b{	
		top: 2.2rem;
	}
	.header_right .nav .nav-ul .subpage-solution li{
		display: inline-block;
		vertical-align: top;
		padding: 0.4rem 0 0.1rem 0 !important;
		width: 100% !important;
	}
	.header_right .nav .nav-ul .subpage-solution li p{
		text-align: center !important;
	}
	.header_right .nav .nav-ul .nav-sapn-product:hover >.subpage-software{
		display: block;
	}  
	.header_right .nav .nav-ul .nav-sapn-solution:hover >.subpage-solution{
		display: block;
	}  
}
@media screen  and (min-width: 1270px) and (max-width:1600px) {
	.header_right .nav ul .langue-li,
	.header_outline{
		display: none;
	}
	.header_left,.header_right,.header_english{
		display: inline-block !important;
		vertical-align: top;
		height: 2.2rem;
		text-align: center;
		color:#ffffff;
	}
	.header_left{
		width: 12%;
		border-right: 1px solid rgba(255, 255, 255, 0.2);
	}
	.header_right{	
		width: 82%;
		text-align: right;
	}
	.header_english{
		width:6%;
		cursor: pointer;
		font-size:0.6rem;
		line-height: 2.2rem;
		font-family: "arial black";
		background-color: #0079e8;
	}
	.header_left img{
		min-width: 3rem;
		margin-top: 10%;
		
	}
	.header_right .nav{
		width: 70%;
	}
	.header_right .nav .nav-ul .langue-li{
		display: block;
	}
	.header_right .nav .nav-ul span{
		width: 15%;
		line-height: 2.2rem;
		vertical-align: middle;
		display: inline-block;
		position: relative;
	}
	.header_right .nav .nav-ul span a{
		display: block;
		color: #ffffff;
		text-align: center;
		width: 100%;
		height: 100%;
	}
	/* 二级导航 */
	.header_right .nav .nav-ul .subpage-nav{
		position: absolute;
		background: #f3f6f8;
		color: #000;
	}
	.header_right .nav .nav-ul .subpage-nav b{
		position: absolute;
		width: 0;
		height: 0;
		border-right: 0.2rem solid transparent;
		border-left: 0.2rem solid transparent;
		border-bottom: 0.2rem solid #fff;
		color: #fff;
		top: -0.2rem;
	}
	.header_right .nav .nav-ul .subpage-nav li{
		display: inline-block;
		vertical-align: top;
		padding-bottom: 0.4rem;
		width: 40%;
	}
	.header_right .nav .nav-ul .subpage-nav li:last-child{
		width: 60%;
	}
	.header_right .nav .nav-ul .subpage-nav li p,
	.header_right .nav .nav-ul .subpage-nav li h5{
		display: block;
		font-size: 0.35rem;
		width: 100%;
		color: #535353;
		text-align: left;
	}
	.header_right .nav .nav-ul .subpage-nav li p{
		color: #535353;
		line-height: 0.5rem;
	}
	.header_right .nav .nav-ul .subpage-nav li h5{
		display: block;
		line-height: 1.2rem;
		width: 100%;
		background: #ffffff;
		border-bottom: solid 2px #1e65c1;
		color: #2e4446;
		font-size: 0.4rem;
		margin-bottom: 0.8rem;
	}
	.header_right .nav .nav-ul .subpage-nav span{
		font-size: 0.2rem;
		display: inline-block;
		vertical-align: top;
		line-height: 0.4rem;
		width: 0.4rem;
		height: 0.4rem;
		border-radius: 50%;
		border: solid 1px #535353;
		margin-right: 0.4rem;
	}
	.header_right .nav .nav-ul .subpage-nav span svg{
		margin-left: 14%;
		font-size: 0.28rem;
	}
	/* 产品中心二级产品 */
	.header_right .nav .nav-ul .subpage-software{
		display: none;
		width: 12rem;
		left: -3.4rem;
		top: 1.7rem;
	}
	.header_right .nav .nav-ul .subpage-software b{
		left: 4.8rem;
	}
	.header_right .nav .nav-ul .subpage-software li p,
	.header_right .nav .nav-ul .subpage-software li h5{
		padding-left: 0.7rem;
	}
	/* 产品中心二级解决方案*/
	.header_right .nav .nav-ul .subpage-solution{
		width: 5rem;
		left: -1rem;
		top: 1.7rem;
		display: none;
	}
	.header_right .nav .nav-ul .subpage-solution b{
		left: 2.2rem;
	}
	.header_right .nav .nav-ul .subpage-solution li{
		display: inline-block;
		vertical-align: top;
		padding: 0.4rem 0 0.1rem 0 !important;
		width: 100% !important;
	}
	.header_right .nav .nav-ul .subpage-solution li p{
		text-align: center !important;
	}
	.header_right .nav .nav-ul .nav-sapn-product:hover >.subpage-software{
		display: block;
	}  
	.header_right .nav .nav-ul .nav-sapn-solution:hover >.subpage-solution{
		display: block;
	}  
}
@media screen  and (min-width: 640px){
	.header_right .nav .nav-ul .langue-li{
		display: none;
	}
}
@media screen  and (min-width: 1600px){
	.header_outline{
		display: none;
	}
	.header_left,.header_right,.header_english{
		display: inline-block !important;
		vertical-align: top;
		height: 2.2rem;
		text-align: center;
		color:#ffffff;
	}
	.header_left{
		width: 12%;
		border-right: 1px solid rgba(255, 255, 255, 0.2);
	}
	.header_right{	
		width: 82%;
		text-align: right;
	}
	.header_english{
		width:6%;
		cursor: pointer;
		font-size:0.6rem;
		line-height: 2.2rem;
		font-family: "arial black";
		background-color: #0079e8;
	}
	.header_left img{
		min-width: 3rem;
		margin-top: 10%;
		
	}
	.header_right .nav{
		width: 70%;
	}
	.header_right .nav .nav-ul span{
		width: 10%;
		line-height: 2.2rem;
		vertical-align: middle;
		display: inline-block;
		position: relative;
		margin: 1%;
		text-align: center;
	}
	.header_right .nav .nav-ul span a{
		display: block;
		color: #ffffff;
		text-align: center;
		width: 100%;
		height: 100%;
	}
	/* .header_right .nav .nav-ul .active a {
		color:#0079e8;
		width: 100%;
		display: block;
	} */
	/* 二级导航 */
	.header_right .nav .nav-ul .subpage-nav{
		position: absolute;
		background: #f3f6f8;
		color: #000;
	}
	.header_right .nav .nav-ul .subpage-nav b{
		position: absolute;
		width: 0;
		height: 0;
		border-right: 0.2rem solid transparent;
		border-left: 0.2rem solid transparent;
		border-bottom: 0.2rem solid #fff;
		color: #fff;
		top: -0.2rem;
	}
	.header_right .nav .nav-ul .subpage-nav li{
		display: inline-block;
		vertical-align: top;
		padding-bottom: 0.4rem;
		width: 40%;
	}
	.header_right .nav .nav-ul .subpage-nav li:last-child{
		width: 60%;
	}
	.header_right .nav .nav-ul .subpage-nav li p,
	.header_right .nav .nav-ul .subpage-nav li h5{
		display: block;
		font-size: 0.35rem;
		width: 100%;
		color: #535353;
		text-align: left;
	}
	.header_right .nav .nav-ul .subpage-nav li p{
		color: #535353;
		line-height: 0.5rem;
	}
	.header_right .nav .nav-ul .subpage-nav li h5{
		display: block;
		line-height: 1.2rem;
		width: 100%;
		background: #ffffff;
		border-bottom: solid 2px #1e65c1;
		color: #2e4446;
		font-size: 0.4rem;
		margin-bottom: 0.8rem;
	}
	.header_right .nav .nav-ul .subpage-nav span{
		font-size: 0.2rem;
		display: inline-block;
		vertical-align: top;
		line-height: 0.4rem;
		width: 0.4rem;
		height: 0.4rem;
		border-radius: 50%;
		border: solid 1px #535353;
		margin-right: 0.4rem;
	}
	.header_right .nav .nav-ul .subpage-nav span svg{
		margin-left: 14%;
		font-size: 0.28rem;
	}
	/* 产品中心二级产品 */
	.header_right .nav .nav-ul .subpage-software{
		display: none;
		width: 14rem;
		left: -3.4rem;
		top: 1.7rem;
	}
	.header_right .nav .nav-ul .subpage-software b{
		left: 4.8rem;
	}
	.header_right .nav .nav-ul .subpage-software li p,
	.header_right .nav .nav-ul .subpage-software li h5{
		padding-left: 0.7rem;
	}
	/* 产品中心二级解决方案*/
	.header_right .nav .nav-ul .subpage-solution{
		width: 5rem;
		left: -1rem;
		top: 1.7rem;
		display: none;
	}
	.header_right .nav .nav-ul .subpage-solution b{
		left: 2.2rem;
	}
	.header_right .nav .nav-ul .subpage-solution li{
		display: inline-block;
		vertical-align: top;
		padding: 0.4rem 0 0.1rem 0 !important;
		width: 100% !important;
	}
	.header_right .nav .nav-ul .subpage-solution li p{
		text-align: center !important;
	}
	.header_right .nav .nav-ul .nav-sapn-product:hover >.subpage-software{
		display: block;
	}  
	.header_right .nav .nav-ul .nav-sapn-solution:hover >.subpage-solution{
		display: block;
	}  
}

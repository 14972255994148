.subpage .section .customer-img{
	margin: 0 auto;
	text-align: center;
}
.subpage .section .customer-img img{
	border:1px solid #dcdcdc;
	display: inline-block;
}
@media screen and (max-width:568px) {
    .subpage .section .customer-img{
        padding: 0;
        width: 100%;
        text-align: left;
        padding-bottom: 0.2rem;
    }
    .subpage .section .customer-img img{
        width: 32%;
        margin:0 1% 2% 0;
    }
}
@media screen  and (min-width: 568px) and (max-width:1280px) {
    .subpage .section .customer-img{
        width: 100%;
        padding: 0;
		text-align: left;
		padding-bottom: 0.2rem;
    }
	.subpage .section .customer-img img{
		width: 18%;
		margin:1%;
	}
}
@media screen  and (min-width: 1280px) and (max-width:1600px) {	
	.subpage .section .customer-img{
		width: 80%;
		text-align: left;
		padding-bottom: 2%;
    }
	.subpage .section .customer-img img{
		width: 19%;
		margin:0 1% 2% 0;
		margin-right: 1%;
	}
}
@media screen  and (min-width: 1600px){
	.subpage .section .customer-img{
		width: 62%;
        padding: 0;
        text-align: left;
    }
	.subpage .section .customer-img img{
		width: 19%;
		margin:0 1% 2% 0;
		margin-right: 1%;
	}
}
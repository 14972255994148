.subpage .product-header{
	background-image: url(../../img/CTC_01.jpg);
	background-size: 100% 100%;
}
.subpage .product-node-header{
	background-image: url(../../img/node_01.jpg);
	background-size: 100% 100%;
}
.subpage .data-node-img{
	width: 100%;
	display: block;
	list-style: none;
}
.subpage .data-node-img li{
	width: 48%;
	margin: 0 1%;
	height: 600px;
	list-style: none;
	display: inline-block;
}
.subpage .data-node-img .one{
	background: url(../../img/nodeImg1.png) center center no-repeat;
	background-size: contain;
}
.subpage .data-node-img .two{
	background: url(../../img/nodeImg2.png) center center no-repeat;
	background-size: contain;
}
.subpage .prouct-first-section,
.subpage .prouct-second-section{
	margin: 0 auto;
	text-align: center;
}
.subpage .prouct-first-section .introduce-p{
	display: block;
	text-indent: 2em;
	text-align: left;
	margin: 0;
}
.subpage .prouct-first-section .introduce-ul{
	padding: 0;
}
.subpage .prouct-first-section .introduce-ul li{
	vertical-align: top;
	background: white;
	display: inline-block;
	box-shadow: 0px 0px 10px #dcdcdc;
}
.subpage .prouct-first-section .introduce-ul li h5{
	background: #0079e8;
	color: white;
}
.subpage .prouct-first-section .introduce-ul li p{
	text-align: left;
}
.subpage .prouct-second-section ul,
.subpage .prouct-second-section ul li{
	list-style:none;
	padding: 0;
	margin: 0;
} 
.subpage .prouct-second-section ul li{
	background: #fff;
	vertical-align: top;
	display: inline-block;
}
.subpage .prouct-second-section ul li h5,
.subpage .prouct-second-section ul li p{
	text-align: left;
	display: block;
}
.subpage .prouct-second-section ul li h5{
	border-bottom: solid 0.01rem #000;
}
@media screen  and (max-width:640px){
	.subpage .data-node-img{
		width: 100%;
		display: block;
		list-style: none;
		padding: 0;
		margin: 0;
	}
	.subpage .data-node-img li{
		padding: 0;
		margin-bottom: 0.3rem;
		width: 100%;
		height: 6.1rem;
		list-style: none;
		display: inline-block;
	}
	.subpage .data-node-img .one{
		background: url(../../img/nodeImg1.png) center center no-repeat;
		background-size: contain;
	}
	.subpage .data-node-img .two{
		background: url(../../img/nodeImg2.png) center center no-repeat;
		background-size: contain;
	}
    .subpage .product-header {
        background-image: url(../../img/CTC_01.jpg);
        background-size: cover;
    }
	.subpage .prouct-first-section,
	.subpage .prouct-second-section{
		width: 90%;
	}
	.subpage .prouct-first-section .introduce-p{
		width: 100%;
		line-height: 0.5rem;
		font-size: 0.26rem;
	}
	.subpage .prouct-first-section .introduce-ul{
		margin-top: 0.1rem;
	}
	.subpage .prouct-first-section .introduce-ul li{
		width: 100%;
		margin: 3% 1.5%;
		line-height: 0.4rem;
	}
	.subpage .prouct-first-section .introduce-ul li h5{
		width: 100%;
		height: 0.8rem;
		line-height: 0.8rem;
		font-size: 0.3rem;
	}
	.subpage .prouct-first-section .introduce-ul li p{
		padding: 0 10%;
		font-size: 0.26rem;
	} 
	.subpage .prouct-second-section{
		padding-bottom: 0.2rem;
	}
	.subpage .prouct-second-section ul li h5,
	.subpage .prouct-second-section ul li p{
		font-size: 0.3rem;
	}
	.subpage .prouct-second-section ul li{
		width: 100%;
		display: block;
		margin: 0rem 1%;
		padding: 1% 5%;
		min-height: 4.4rem;
		margin-bottom: 0.2rem;
	}
	.subpage .prouct-second-section ul li img{
		width: 70%;
		margin: 0 15%;
	}
	.subpage .prouct-second-section ul li h5{
		font-size: 0.3rem;
		line-height: 0.6rem;
	}
	.subpage .prouct-second-section ul li p{
		font-size: 0.26rem;
		line-height: 0.5rem;
		color: #000;
	}
	.one-section .software-section .cloud-bottom li{
		width:100%;
		padding: 0.3rem 0;
		font-size: 0.26rem;
		display: inline-block;
		vertical-align: top;
		background: white;
		box-shadow: 0px 0px 5px #e2e2e2;
		margin-bottom: 0.3rem;
	}
	.one-section .software-section  .cloud-bottom li p{
		margin-top:0.2rem;
		line-height: 0.5rem;
		margin-bottom: 0;
	}
}
@media screen  and (min-width: 640px) and (max-width:1280px) {
	.subpage .data-node-img{
		width: 100%;
		display: block;
		list-style: none;
		padding: 0;
		margin: 0;
	}
	.subpage .data-node-img li{
		padding: 0;
		margin: 0 1%;
		width: 48%;
		height: 8rem;
		list-style: none;
		display: inline-block;
	}
	.subpage .data-node-img .one{
		background: url(../../img/nodeImg1.png) center center no-repeat;
		background-size: contain;
	}
	.subpage .data-node-img .two{
		background: url(../../img/nodeImg2.png) center center no-repeat;
		background-size: contain;
	}
	.subpage .prouct-first-section,
	.subpage .prouct-second-section{
		width: 80%;
	}
	.subpage .prouct-first-section .introduce-p{
		width: 100%;
		line-height: 0.7rem;
	}
	.subpage .prouct-first-section .introduce-ul{
		margin-top: 1rem;
	}
	.subpage .prouct-first-section .introduce-ul li{
		width: 22%;
		min-height: 6rem;
		margin: 0 1.5%;
		line-height: 0.6rem;
	}
	.subpage .prouct-first-section .introduce-ul li h5{
		width: 100%;
		height: 1.2rem;
		line-height: 1.2rem;
		font-size: 0.45rem;
	}
	.subpage .prouct-first-section .introduce-ul li p{
		padding: 0 10%;
	} 
	.subpage .prouct-second-section ul li{
		width: 23%;
		min-height: 7.8rem;
		margin: 0rem 1%;
		padding: 0.8%;
		margin-bottom: 1rem;
	}
	.subpage .prouct-second-section ul li img{
		width: 70%;
		margin: 0 15%;
	}
	.subpage .prouct-second-section ul li h5,
	.subpage .prouct-second-section ul li p{
		font-size: 0.8rem;
	}
	.subpage .prouct-second-section ul li h5{
		font-size: 0.38rem;
		line-height: 1rem;
	}
	.subpage .prouct-second-section ul li p{
		font-size: 0.35rem;
		line-height: 0.6rem;
		color: #000;
	}	
}
@media screen  and (min-width: 1280px) and (max-width:1600px) {
	.subpage .prouct-first-section,
	.subpage .prouct-second-section{
		width: 80%;
	}
	.subpage .prouct-first-section .introduce-p{
		width: 100%;
		line-height: 0.7rem;
	}
	.subpage .prouct-first-section .introduce-ul{
		margin-top: 1rem;
	}
	.subpage .prouct-first-section .introduce-ul li{
		width: 22%;
		height: 4.8rem;
		margin: 0 1.5%;
		line-height: 0.6rem;
	}
	.subpage .prouct-first-section .introduce-ul li h5{
		width: 100%;
		height: 1.2rem;
		line-height: 1.2rem;
		font-size: 0.45rem;
	}
	.subpage .prouct-first-section .introduce-ul li p{
		padding: 0 10%;
	} 
	.subpage .prouct-second-section ul li{
		width: 23%;
		min-height: 8.3rem;
		margin: 0rem 1%;
		padding: 0.8%;
		margin-bottom: 1rem;
	}
	.subpage .prouct-second-section ul li img{
		width: 70%;
		margin: 0 15%;
	}
	.subpage .prouct-second-section ul li h5,
	.subpage .prouct-second-section ul li p{
		font-size: 0.8rem;
	}
	.subpage .prouct-second-section ul li h5{
		font-size: 0.45rem;
		line-height: 1rem;
	}
	.subpage .prouct-second-section ul li p{
		font-size: 0.35rem;
		line-height: 0.6rem;
		color: #000;
	}	
}
@media screen  and (min-width: 1600px){
	.subpage .prouct-second-section ul{
		text-align: left;
	}
	.subpage .prouct-first-section,
	.subpage .prouct-second-section{
		width: 68%;
	}
	.subpage .prouct-first-section .introduce-p{
		width: 100%;
		line-height: 0.9rem;
		font-size: 0.4rem;
	}
	.subpage .prouct-first-section .introduce-ul{
		margin-top: 1rem;
	}
	.subpage .prouct-first-section .introduce-ul li{
		width: 22%;
		height: 5.7rem;
		margin: 0 1.5%;
		line-height: 0.6rem;
	}
	.subpage .prouct-first-section .introduce-ul li h5{
		width: 100%;
		height: 1.2rem;
		line-height: 1.2rem;
		font-size: 0.5rem;
	}
	.subpage .prouct-first-section .introduce-ul li p{
		padding: 0 10%;
		line-height: 0.8rem;
		font-size: 0.4rem;
	} 
	.subpage .prouct-second-section ul li{
		width: 23%;
		min-height: 9rem;
		margin: 0rem 1%;
		padding: 0.6%;
		margin-bottom: 1rem;
	}
	.subpage .prouct-second-section ul li img{
		width: 70%;
		margin: 0 15%;
	}
	.subpage .prouct-second-section ul li h5,
	.subpage .prouct-second-section ul li p{
		font-size: 0.9rem;
	}
	.subpage .prouct-second-section ul li h5{
		font-size: 0.5rem;
		line-height: 1.2rem;
	}
	.subpage .prouct-second-section ul li p{
		font-size: 0.4rem;
		line-height: 0.6rem;
		color: #000;
	}
	.one-section .software-section .cloud-bottom li{
		width:30%;
		height: 5.5rem;
		padding:3%;
		margin-right: 2%;
		font-size: 0.45rem;
		display: inline-block;
		vertical-align: top;
		background: white;
		box-shadow: 0px 0px 5px #e2e2e2;
	}
	.one-section .software-section  .cloud-bottom li p{
		margin-top:0.2rem;
		line-height: 0.7rem;
	}
}

